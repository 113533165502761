import Dialog from '@material-ui/core/Dialog';
import { random } from 'lodash';
import React, { useState } from 'react';
import Label from '../Label/Label';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  image: {
    cursor: 'pointer',
    height: '200px',
    objectFit: 'cover',
    margin: '20px',
  },
  imageDialog: {
    objectFit: 'cover',
    maxHeight: '80vh',
  },
});

interface Issue {
  id: number;
  key: string;
  picture_urls: string[];
  notes: string;
}

export const GridListPicture: React.FC<{ issue: Issue }> = ({ issue }) => {
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = (image: string) => {
    setSelectedValue(image);
    setOpen(true);
  };
  return (
    <div className="GridListPicture">
      <Label value={`Issue Summary at point ${issue.key}`} />
      <span>{issue.notes}</span>
      <div className="images">
        {issue.picture_urls.map((url) => (
          <img
            src={url}
            key={random(1000)}
            alt={url}
            onClick={() => handleOpen(url)}
            className={classes.image}
          />
        ))}
      </div>
      <SimpleDialog selectedValue={selectedValue} open={open} onClose={handleClose} />
    </div>
  );
};

interface SimpleDialogProps {
  open: boolean;
  selectedValue: string;
  onClose: () => void;
}

export const SimpleDialog = (props: SimpleDialogProps) => {
  const { onClose, selectedValue, open } = props;
  const classes = useStyles();

  return (
    <Dialog maxWidth="lg" onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
      <img className={classes.imageDialog} src={selectedValue} alt={selectedValue} />
    </Dialog>
  );
};
