import React from 'react';
import { Datagrid } from 'react-admin';

interface Props {
  ids?: number[];
  resource?: string;
  emptyMessage?: string;
  [x: string]: any;
}
const CustomDatagrid = (props: Props) => {
  const { emptyMessage, ...rest } = props;
  const { ids, resource } = rest;
  if (ids && resource && ids.length === 0) {
    return (
      <>
        <div style={{ padding: '20px 0' }}>{emptyMessage ?? `No ${resource} is available.`}</div>
      </>
    );
  }

  return <Datagrid {...rest} />;
};

export default CustomDatagrid;
