import { format, parse } from 'date-fns';

const jsFormat = "yyyy-MM-dd'T'HH:mm";
const sqlFormat = 'yyyy-MM-dd HH:mm:ss';
const sqlDateFormat = 'yyyy-MM-dd';
const localeDateFormat = 'dd/MM/yyyy';

/**
 * Convert JS time string to SQL time string
 * For example, from "2020-01-01T12:22" to "2020-01-01 12:22:00"
 * @param jsString
 */
export const convertJsToSql = (jsString: string) => format(parse(jsString, jsFormat, new Date()), sqlFormat);

/**
 * Convert SQL time string to format used by TextInput
 * For example, from "2020-01-01 12:22:00" to "2020-01-01T12:22"
 * @param sqlString
 */
export const convertSqlToJs = (sqlString: string | null) => {
  if (!sqlString) {
    return format(new Date(), jsFormat);
  } else {
    return format(parse(sqlString, sqlFormat, new Date()), jsFormat);
  }
};

/**
 * Convert SQL date string to Locale date format
 * @param sqlString
 */
export const convertSqlDateToLocale = (sqlString: string) =>
  format(parse(sqlString, sqlDateFormat, new Date()), localeDateFormat);
