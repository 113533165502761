import React from 'react';
import { SelectInput } from 'react-admin';
import { OrderStatus } from '../utils/const';
import { translateStatus } from './StatusField/StatusField';

const OrderStatusSelectInput = (props: any) => {
  const choices = Object.keys(OrderStatus).map((status) => ({
    id: status,
    name: translateStatus(status),
  }));
  return <SelectInput source="status" choices={choices} {...props} />;
};

export default OrderStatusSelectInput;
