import React from 'react';
import { get } from 'lodash';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import { useHistory } from 'react-router-dom';
import './index.scss';

interface Profile {
  id: number;
  full_name: string;
  photo_profile_url?: string;
  user: {
    email: string;
  };
  phone?: string;
}

interface Props {
  className?: string;
  record?: any;
  resource?: string;
  basePath?: string;
  label?: string;
  source: any;
  clickable?: boolean;
}

const ProfileField = ({ record, source, clickable = true, ...rest }: Props) => {
  const history = useHistory();
  const profile: Profile = get(record, source);

  if (!profile) {
    return <Box>Unassigned</Box>;
  }

  const style = {
    imageBox: {},
    textBox: {
      marginLeft: '10px',
    },
  };

  const handleClick = () => {
    return clickable ? history.push(`/${source}s/${profile.id}`) : {};
  };

  return (
    <Box
      className="ProfileField"
      style={{ cursor: 'pointer', width: '300px', display: 'flex', alignItems: 'center' }}
      onClick={handleClick}
    >
      <div style={style.imageBox}>
        <Avatar src={profile?.photo_profile_url} alt={profile.full_name} style={{ maxWidth: '100%' }} />
      </div>
      <Box style={style.textBox}>
        <div style={{ fontWeight: 'bold' }}>{profile.full_name}</div>
        <div>ID: {profile.id}</div>
        <div>{profile.user.email}</div>
        {profile.phone && <div>{profile.phone}</div>}
      </Box>
    </Box>
  );
};

ProfileField.defaultProps = {
  addLabel: true,
};

export default ProfileField;
