import Box from '@material-ui/core/Box';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { get } from 'lodash';
import React from 'react';

interface Record {
  rating: number | null;
}

interface Props {
  className?: string;
  record?: Record;
  resource?: string;
  basePath?: string;
  label?: string;
  source: any;
}


const Field = ({ record, source }: Props) => {
  const rating: number = get(record, source);

  if (!record || !rating) {
    return <Box>-</Box>;
  }

  const maxRating = 5;
  let ratings = [];
  for (let i = 0; i < maxRating; i++) {
    if (i < rating) {
      ratings.push(<StarIcon />);
    } else {
      ratings.push(<StarBorderIcon />);
    }
  }

  return (
    <Box component="fieldset" mb={3} borderColor="transparent">
      {ratings}
    </Box>
  );
};

Field.defaultProps = {
  addLabel: true,
};

export default Field;