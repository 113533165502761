import React from 'react';
import { Edit, SimpleForm, SelectInput } from 'react-admin';
import { TextInput, DateInput, TextField } from 'react-admin';
import TitleBreadcrumb from '../../components/TitleBreadcrumb';
import { ProfileField } from '../../components/ProfileField';
import { useQuery, Error } from 'react-admin';
import { Car } from '../../types';
import { SimpleDialog } from '../../components/InspectionDiagram/GridListPicture';
import { makeStyles } from '@material-ui/core/styles';
import CarModelInput from '../../components/CarModelInput';
import CarMakeInput from '../../components/CarMakeInput';

const useStyles = makeStyles({
  images: {
    '& img': {
      cursor: 'pointer',
      height: '200px',
      objectFit: 'cover',
      margin: '20px',
    },
  },
});

const CustomerCarEdit = (props: any) => {
  const breadcrumb = [
    { link: '/customer-cars', title: 'Customer Cars' },
    { link: `/customer-cars/${props.id}`, title: props.id },
  ];

  return (
    <>
      <TitleBreadcrumb paths={breadcrumb} />
      <Edit undoable={false} {...props} title="Edit Customer Car">
        <SimpleForm>
          <TextInput source="id" disabled />
          <CarModelInput />
          <TextInput source="licence_plate_number" />
          <DateInput source="registration_date" />
          <SelectInput
            source="transmission"
            choices={[
              { id: 'auto', name: 'Auto' },
              { id: 'manual', name: 'Manual' },
            ]}
          />
          <TextInput source="notes" />
          <ShowProfile />
          <CarGrid />
        </SimpleForm>
      </Edit>
    </>
  );
};

interface InputProps {
  record?: Car;
}
const ShowProfile = (props: InputProps) => {
  const { record } = props;
  const { data, loading, error } = useQuery({
    type: 'getOne',
    resource: 'customers',
    payload: { id: record?.customer_id },
  });

  if (loading) return <>Loading...</>;
  if (error) return <Error />;
  if (!data) return null;

  const user = {
    customer: data,
  };

  return <ProfileField record={user} source="customer" clickable={false} />;
};

const CarGrid = (props: InputProps) => {
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState('');
  const classes = useStyles();

  const handleOpen = (image: string) => {
    setSelectedValue(image);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedValue('');
  };

  return (
    <div className="container">
      <div className={classes.images}>
        {props.record?.photos.map((media) => (
          <img src={media.url} key={media.id} alt={media.url} onClick={() => handleOpen(media.url)} />
        ))}
      </div>
      <SimpleDialog selectedValue={selectedValue} open={open} onClose={handleClose} />
    </div>
  );
};

export default CustomerCarEdit;
