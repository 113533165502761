import React from 'react';
import { DateInput, NumberInput, SelectInput, SimpleForm, TextInput } from 'react-admin';
import { useStyle } from '../../hooks/style';
import { validate } from './index';
import SaveDeleteToolbar from '../../components/SaveDeleteToolbar';

interface Props {
  mode: 'create' | 'edit';
  [x: string]: any;
}
const PromoCodeForm = ({ mode, ...rest }: Props) => {
  const classes = useStyle();
  return (
    <SimpleForm
      validate={validate}
      {...rest}
      toolbar={
        <SaveDeleteToolbar
          confirmDeleteContent={`Confirm to delete?`}
          savePermission={'promo-codes.update'}
          deletePermission={`promo-codes.delete`}
        />
      }
    >
      <TextInput source="code" fullWidth disabled={mode == 'edit'} />
      <DateInput source="start_date" formClassName={classes.leftCol} fullWidth />
      <DateInput source="end_date" formClassName={classes.rightCol} fullWidth />
      <TextInput source="value" formClassName={classes.leftCol} fullWidth />
      <SelectInput
        source="type"
        choices={[
          { id: 'percentage', name: 'Percentage' },
          { id: 'fixed_amount', name: 'Dollar' },
        ]}
        formClassName={classes.rightCol}
        fullWidth
      />
      <NumberInput
        label="How Many Usages Before Fully Redeemed (0 = Unlimited)"
        source="usage_max_count"
        formClassName={classes.leftCol}
        fullWidth
        initialValue={0}
        allowEmpty
      />
      <SelectInput
        source="status"
        choices={[
          { id: 'active', name: 'Active' },
          { id: 'inactive', name: 'Inactive' },
          { id: 'expired', name: 'Expired' },
          { id: 'redeemed', name: 'Fully Redeemed' },
        ]}
        defaultValue="active"
        formClassName={classes.rightCol}
        fullWidth
      />
    </SimpleForm>
  );
};

export default PromoCodeForm;
