import React from 'react';
import { Datagrid, Filter, List, ReferenceField, TextField, TextInput } from 'react-admin';
import CarModelInput from '../../components/CarModelInput';
import CustomerInput from '../../components/CustomerInput';
import IdInput from '../../components/IdInput';
import TitleBreadcrumb from '../../components/TitleBreadcrumb';

const CustomerCarList = (props: any) => (
  <>
    <TitleBreadcrumb paths={[]} title="Customer Cars" />
    <List exporter={false} filters={<CustomFilter />} {...props}>
      <Datagrid rowClick="edit">
        <TextField source="id" label="ID" />
        <ReferenceField label="Customer" source="customer_id" reference="customers">
          <TextField source="full_name" />
        </ReferenceField>
        <TextField source="model.name" label="Car Model" />
        <TextField source="licence_plate_number" label="Plate" />
      </Datagrid>
    </List>
  </>
);

const CustomFilter = (props: any) => (
  <Filter {...props}>
    <IdInput source="id" />
    <CustomerInput source="customer_id" label="Customer" />
    <CarModelInput label="Car Model" />
    <TextInput source="licence_plate_number" label="Plate" />
  </Filter>
);

export default CustomerCarList;
