import React, { FC, useEffect, useState } from "react";
import { FieldProps } from "../../types";
import { useDataProvider, Error } from "react-admin";
import LinearProgress from "@material-ui/core/LinearProgress";

const TotalPromoUsageField: FC<FieldProps<any>> = ({ record }) => {
    const dataProvider = useDataProvider();
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();

    useEffect(() => {
        dataProvider
            .getList("promo-code-usages", { filter: { code: record?.code } })
            .then(({ total, data }: any) => {
                setTotal(total);
                setLoading(false);
            })
            .catch((error: any) => {
                setError(error);
                setLoading(false);
            });
    }, []);

    if (loading) return <LinearProgress />;
    if (error) return <Error />;
    return <span>{total}</span>;
};
TotalPromoUsageField.defaultProps = { label: "Usage(s)" };

export default TotalPromoUsageField;
