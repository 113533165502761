import React from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';
import TitleBreadcrumb from '../../components/TitleBreadcrumb';

interface Props {}

const ServiceAdvisorCreate = (props: Props) => {
  const breadcrumb = [
    { title: 'Ambassadors', link: '/advisors' },
    { title: 'Create', link: '/advisors/create' },
  ];

  return (
    <>
      <TitleBreadcrumb paths={breadcrumb} />
      <Create {...props}>
        <SimpleForm>
          <TextInput source="user.email" type="email" label="Email for Login" />
          <TextInput source="full_name" label="SA Full Name" />
          <TextInput source="phone" label="Mobile Number" inputProps={{ maxLength: 8 }} />
        </SimpleForm>
      </Create>
    </>
  );
};

export default ServiceAdvisorCreate;
