import React from 'react';
import { List, Datagrid, TextField, Filter, TextInput, SelectInput } from 'react-admin';
import { Helmet } from 'react-helmet';
import StatusField from '../../components/StatusField';
import { PromoCodeType, PromoCodeStatus } from '../../utils/const';
import CustomDateField from '../../components/CustomDateField';
import TotalPromoUsageField from './TotalPromoUsageField';
import ListActions from '../../components/ListActions';

const PromoFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Exact Code" source="code" />
    <SelectInput
      label="Type"
      choices={Object.entries(PromoCodeType).map((entry) => {
        let [key, value] = entry;
        return { id: key, name: value };
      })}
      source="type"
    />
    <SelectInput
      label="Status"
      choices={Object.entries(PromoCodeStatus).map((entry) => {
        let [key, value] = entry;
        return { id: key, name: value };
      })}
      source="status"
    />
  </Filter>
);

const PromoCodeList = (props: any) => (
  <List
    exporter={false}
    filters={<PromoFilter />}
    bulkActionButtons={false}
    {...props}
    title="Promo Code"
    actions={<ListActions permission="promo-codes.create" />}
  >
    <Datagrid rowClick="edit">
      <Helmet>
        <title>Promo Code</title>
      </Helmet>
      <TextField source="code" />
      <TextField source="value" />
      <TextField source="type" className="capitalized" />
      <TextField label="Max Usage" source="usage_max_count" className="text-right" />
      <CustomDateField label="Start Date" source="start_date" />
      <CustomDateField label="End Date" source="end_date" />
      <CustomDateField label="Created Date" source="created_at" />
      <TotalPromoUsageField source="code" />
      <StatusField source="status" />
    </Datagrid>
  </List>
);

export default PromoCodeList;
