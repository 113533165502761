import React from 'react';
import { AutocompleteInput, ReferenceInput } from 'react-admin';

const Input = (props: any) => (
  <ReferenceInput label="Car Make" source="car_maker_id" reference="car-makers" {...props}>
    <AutocompleteInput
      optionText="name"
      shouldRenderSuggestions={(val: string) => {
        return val.trim().length > 2;
      }}
    />
  </ReferenceInput>
);

export default Input;
