import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { eachDayOfInterval, format } from 'date-fns';
import React from 'react';
import { Line } from 'react-chartjs-2';
import { getReport } from '../../utils/tools';

export interface Report {
  fromDate: string;
  toDate: string;
  type: 'customer_acquisition' | 'sales' | 'orders';
  title?: string;
}

interface Query {
  labels: string[];
  fromDate: string;
  toDate: string;
  onLoad: (data: number[]) => void;
  type: string;
}

const LineDiagram = ({ fromDate, toDate, type, title }: Report) => {
  const [labels, setLabels] = React.useState<string[]>([]);
  const [data, setData] = React.useState<number[]>([]);
  const query = ({ fromDate, toDate, labels, onLoad, type }: Query) => {
    getReport(type, fromDate, toDate)
      .then(({ json }) =>
        labels.map((date) => {
          const index = json.findIndex((el: any) => el.period === date);
          return index >= 0 ? json[index].value : 0;
        }),
      )
      .then(onLoad)
      .catch((error: any) => console.log(error))
      .finally(() => setIsLoading(false));
  };
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    setIsLoading(true);
    const newLabels = createLabels(fromDate, toDate);
    setLabels(newLabels);

    query({ labels: newLabels, fromDate, toDate, onLoad: setData, type });
  }, [fromDate, toDate, type]);

  const createLabels = (fromDate: string, toDate: string) => {
    return eachDayOfInterval({ start: new Date(fromDate), end: new Date(toDate) }).map((date) =>
      format(date, 'yyyy-MM-dd'),
    );
  };

  return (
    <Card className="LineDiagram">
      <CardContent>
        <div style={{ width: '500px' }}>
          <Line
            data={{
              labels,
              datasets: [
                {
                  label: title,
                  fill: false,
                  lineTension: 0.5,
                  backgroundColor: 'rgba(75,192,192,1)',
                  borderColor: 'rgba(10,10,10,1)',
                  borderWidth: 2,
                  data,
                },
              ],
            }}
            options={{
              title: {
                display: true,
                text: title,
              },
              legend: {
                display: false,
              },
            }}
          />
        </div>
      </CardContent>
    </Card>
  );
};

export default LineDiagram;
