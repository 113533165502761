import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DoneOutlinedIcon from '@material-ui/icons/DoneOutlined';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import NotInterestedOutlinedIcon from '@material-ui/icons/NotInterestedOutlined';
import { get } from 'lodash';
import React from 'react';
import { useDataProvider, usePermissions } from 'react-admin';
import { Order, ProductItem } from '../../types';
import { FinanceDocumentStatus } from '../../utils/const';
import { money } from '../../utils/tools';

interface Props {
  record: Order;
}

const QuotationShow = ({ record: order, ...props }: Props) => {
  const { quotation } = order;
  const { permissions } = usePermissions();

  if (!permissions) {
    return <>Loading permissions...</>;
  }

  if (!quotation) {
    return <div style={{ padding: '20px 0' }}>Quotation has not been created yet.</div>;
  }

  return (
    <>
      {order.revised_quotation && (
        <>
          <CancelRevisionButton order={order} />
          <Document
            order={order}
            quotationString="revised_quotation"
            salesOrderString="revised_sales_order"
            title="Revised Quotation"
            permissions={permissions}
          />
          <hr />
        </>
      )}
      <Document
        order={order}
        quotationString="quotation"
        salesOrderString="sales_order"
        title="Active Quotation"
        permissions={permissions}
      />
    </>
  );
};

interface DocumentProps {
  order: Order;
  quotationString: string;
  salesOrderString: string;
  title: string;
  permissions: (x: string) => boolean;
}
const Document = ({ order, quotationString, salesOrderString, title, permissions }: DocumentProps) => {
  const quotation = get(order, quotationString);
  const invoice = get(order, salesOrderString);

  /**
   * Map the items in quotation to see if it has been accepted by customer.
   * Map the items in quotation to see if it was requested by customer.
   */
  const acceptanceItems = quotation?.items.map((quotationItem: ProductItem) => {
    // for every quotation item, check if the item is in the invoice or not
    // we check by comparing the product_id as well ass the description.
    // Because, an item in invoice is unique if product_id and description are the same.
    const invoiceItem = invoice?.items.find(
      (item: ProductItem) =>
        item.product_id === quotationItem.product_id && item.description == quotationItem.description,
    );
    const customer_total = invoiceItem?.total ?? 0;
    const is_accepted = invoiceItem ? true : false;

    // for every item in quotation, check if it was requested by customer or not
    const initialOrderItem = order.items.find((item) => item.product_id === quotationItem.product_id);
    const is_requested = initialOrderItem ? true : false;

    return {
      ...quotationItem,
      customer_total,
      is_accepted,
      is_requested,
    };
  });
  const remarks = quotation?.notes;
  let totalWo = 0;
  let totalCustomer = 0;

  return (
    <div>
      <h2>{title}</h2>
      <TableContainer>
        {/* Show the diff between quotation and sales order */}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>No</TableCell>
              <TableCell>Product Description</TableCell>
              <TableCell align="center">Requested?</TableCell>
              <TableCell align="center">Accepted?</TableCell>
              <TableCell align="right">Workshop Price ($)</TableCell>
              {permissions('quotations.read.customer_price') && (
                <TableCell align="right">Customer Price ($)</TableCell>
              )}
            </TableRow>
          </TableHead>

          <TableBody>
            {acceptanceItems?.map((item: any, counter: number) => {
              return (
                <TableRow key={item.id}>
                  <TableCell>{counter + 1}</TableCell>
                  <TableCell>{item.description}</TableCell>
                  <TableCell align="center">
                    {!item.is_requested && <span>Recommended</span>}
                    {item.is_requested && <DoneOutlinedIcon fontSize="small" color="primary" />}
                  </TableCell>
                  <TableCell align="center">
                    {!item.is_accepted && <HelpOutlineOutlinedIcon fontSize="small" color="disabled" />}
                    {item.is_accepted && <DoneOutlinedIcon fontSize="small" color="primary" />}
                  </TableCell>
                  <TableCell align="right">{money(item.total)}</TableCell>
                  {permissions('quotations.read.customer_price') && (
                    <TableCell align="right">{money(item.customer_total)}</TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {remarks !== '' && (
        <div
          style={{
            marginTop: '20px',
            marginBottom: '20px',
            maxWidth: '20vw',
            padding: '0px',
          }}
        >
          <div>
            <strong>Estimated Collection Date:</strong> {order.collection_date}
          </div>
          <div>
            <strong>Remarks: </strong>
            <div dangerouslySetInnerHTML={{ __html: remarks }} />
          </div>
        </div>
      )}
    </div>
  );
};

const CancelRevisionButton = ({ order }: { order: Order }) => {
  const [open, setOpen] = React.useState(false);
  const dataProvider = useDataProvider();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleConfirm = () => {
    dataProvider
      .update('orders', {
        id: order.id,
        data: {
          revised_quotation: {
            status: FinanceDocumentStatus.STATUS_CANCELLED,
          },
        },
      })
      .then(({ data }: { data: Order }) => console.log(data))
      .catch((error: any) => console.log(error));
  };

  return (
    <div>
      <Button color="secondary" size="small" onClick={handleClickOpen}>
        Cancel Revised Quotation
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Cancel Revised Quotation</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please confirm that you want to cancel the revision on the quotation.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="default" autoFocus>
            Cancel
          </Button>
          <Button onClick={handleConfirm} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default QuotationShow;
