import React from 'react';
import {
  Edit,
  RadioButtonGroupInput,
  useDataProvider,
  FormWithRedirect,
  SimpleForm,
  TextInput,
  Toolbar,
  SaveButton,
} from 'react-admin';
import ImageInput from '../../../components/ImageInput';
import SaveDeleteToolbar from '../../../components/SaveDeleteToolbar';
import TitleBreadcrumb from '../../../components/TitleBreadcrumb';
import Button from '@material-ui/core/Button';
import { Card, CardContent } from '@material-ui/core';
import Recover from '@material-ui/icons/Restore';

const ServiceToolBar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton />
    <Button
      disabled={props.saving}
      style={{ marginLeft: 15 }}
      variant="contained"
      color="secondary"
      startIcon={<Recover />}
      onClick={props.recover}
    >
      Recover
    </Button>
  </Toolbar>
);

const ServiceEdit = (props: any) => {
  const { id } = props;
  const dataProvider = useDataProvider();
  const breadcrumb = [
    { link: '/products', title: 'Products & Services' },
    { link: `/products/${id}`, title: id },
  ];

  const onRecover = async (record: any, onSuccess: any) => {
    await dataProvider.update('products', {
      id: record.id,
      data: {
        ...record,
        deleted_at: '',
      },
    });
    onSuccess();
  };

  return (
    <>
      <TitleBreadcrumb paths={breadcrumb} />
      <Edit undoable={false} {...props} title="Edit Service">
        <FormWithRedirect
          {...props}
          render={(formProps: any) => (
            <Card>
              <form>
                <CardContent>
                  <TextInput source="id" disabled />
                  <TextInput source="code" fullWidth />
                  <TextInput source="description" multiline fullWidth />
                  <TextInput source="price" type="number" fullWidth />
                  <TextInput source="display_order" type="number" fullWidth />
                  <ImageInput source="photo_url" label="Photo" />
                </CardContent>

                {formProps.record.deleted_at !== null && (
                  <ServiceToolBar
                    recover={() => onRecover(formProps.record, formProps.handleSubmitWithRedirect)}
                    saving={formProps.saving}
                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  />
                )}
                {formProps.record.deleted_at === null && (
                  <SaveDeleteToolbar
                    record={formProps.record}
                    basePath={formProps.basePath}
                    undoable={true}
                    invalid={formProps.invalid}
                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                    saving={formProps.saving}
                    resource="products"
                    confirmDeleteContent={`Are you sure to delete this?`}
                    savePermission={`products.update`}
                    deletePermission={`products.delete`}
                  />
                )}
              </form>
            </Card>
          )}
        />
      </Edit>
    </>
  );
};

export default ServiceEdit;
