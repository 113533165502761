import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Title, useDataProvider, Loading } from 'react-admin';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { parseICS } from '../../utils/parseICS';
import './ics.scss';
import { asyncForEach } from '../../utils/asyncForeach';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { eachWeekendOfYear, isSunday, format } from 'date-fns';
import TitleBreadcrumb from '../../components/TitleBreadcrumb';

interface IHoliday {
  date: string;
  title: string;
}

export default (props: any) => {
  const [iscString, setIcsString] = React.useState('');
  const [disabled, setDisabled] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const dataProvider = useDataProvider();
  const history = useHistory();
  const [checked, setChecked] = React.useState(true);
  const title = `Import Holidays`;

  const breadcrumb = [
    { link: '/public-holidays', title: 'Holidays' },
    { link: `/init-year`, title },
  ];

  if (loading) return <Loading />;

  const submit = async () => {
    const days = parseICS(iscString);
    //init the public holiday
    setLoading(true);
    if (days !== null) {
      //post the holiday 1 by 1 with foreach
      await asyncForEach(days, async (day: IHoliday) => {
        try {
          const res = await dataProvider.create('public-holidays', {
            data: {
              date: day.date,
              title: day.title,
            },
          });
          console.log(res);
        } catch (error) {
          console.log(error);
        }
      });
    }

    if (checked) {
      // init weekend
      let now = new Date();
      now.setMonth(1);
      now.setDate(1);

      //get the weekend of this year
      let weekends = eachWeekendOfYear(now)
        .filter((date) => isSunday(date))
        .map((date) => format(date, 'yyyy-MM-dd'));

      //post the holiday 1 by 1 with foreach
      await asyncForEach(weekends, async (day: string) => {
        try {
          const res = await dataProvider.create('public-holidays', {
            data: {
              date: day,
              title: 'Weekend',
            },
          });
          console.log(res);
        } catch (error) {
          console.log(error);
        }
      });
    }

    setLoading(false);
    history.push('/public-holidays');
  };

  const handleChange = (e: any) => {
    const input = e.target;
    if (input && input.files[0].type === 'text/calendar') {
      const reader = new FileReader();
      reader.onload = function () {
        const text = reader.result;
        setIcsString(text ? text.toString() : '');
      };
      reader.readAsText(input.files[0]);
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const handleCheck = (e: any) => {
    setChecked(e.target.checked);
  };

  return (
    <div>
      <TitleBreadcrumb paths={breadcrumb} />
      <Card>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <Title title={title} />
        <CardContent>
          <div className="ics-container">
            <Typography variant="h6" gutterBottom>
              {title}
            </Typography>
            This form allows you to import holidays from .ics file. For example, you can get holiday list
            (with .ics format) from{' '}
            <a href="https://www.mom.gov.sg/employment-practices/public-holidays" target="_blank">
              MOM Public Holiday page
            </a>
            <TextField
              className="form-control"
              variant="filled"
              onChange={handleChange}
              label="ICS file"
              type="file"
            />
            <FormControlLabel
              control={<Checkbox checked={checked} onChange={handleCheck} name="checkedB" color="primary" />}
              label="Automatically mark weekends as non-operating days"
            />
            <div className="ics-action">
              <Button variant="contained" disabled={disabled} onClick={submit} color="primary">
                Import .ICS File
              </Button>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};
