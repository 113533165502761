import React from 'react';
import { Datagrid, Filter, ImageField, List, SelectInput, TextField, TextInput } from 'react-admin';
import { Helmet } from 'react-helmet';
import IdInput from '../../../components/IdInput';
import ListActions from '../../../components/ListActions';

const MyFilter = (props: any) => (
  <Filter {...props}>
    <IdInput label="Id" source="id" />
    <TextInput label="Name" source="search" />
  </Filter>
);

const CarMakeList = (props: any) => (
  <List
    sort={{ field: 'name', order: 'ASC' }}
    exporter={false}
    filters={<MyFilter />}
    bulkActionButtons={false}
    title="Car Make"
    {...props}
    actions={<ListActions permission={`car-makers.create`} />}
  >
    <Datagrid rowClick="edit">
      <Helmet>
        <title>Car Make</title>
      </Helmet>
      <TextField source="id" />
      <TextField source="name" />
    </Datagrid>
  </List>
);

export default CarMakeList;
