import React from 'react';
import { AutocompleteInput, ReferenceInput } from 'react-admin';

const Input = (props: any) => (
  <ReferenceInput label="Customer" source="customer_id" reference="customers" {...props}>
    <AutocompleteInput
      optionText="full_name"
      shouldRenderSuggestions={(val: string) => {
        return val.trim().length > 2;
      }}
    />
  </ReferenceInput>
);

export default Input;
