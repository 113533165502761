import React, { useEffect } from 'react';
import {
  Datagrid,
  Filter,
  List,
  TextField,
  TextInput,
  FunctionField,
  useDataProvider,
} from 'react-admin';
import Button from '@material-ui/core/Button';
import { Inbox } from '../../types';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Icon from '@material-ui/core/Icon';
import TitleBreadcrumb from '../../components/TitleBreadcrumb';
import CustomDateField from '../../components/CustomDateField';

const useStyles = makeStyles({
  detailContainer: {
    padding: '20px',
    lineHeight: '1.5rem',

    '& .title': {
      fontWeight: 'bold',
      marginBottom: '5px',
    },
    '& .body': {
      marginBottom: '5px',
    },
  },
});

const CustomFilter = (props: any) => <Filter {...props}></Filter>;

interface BodyProps {
  id?: string;
  basePath?: string;
  record?: Inbox;
  [x: string]: any;
}
const InboxBody = ({ record, id, ...rest }: BodyProps) => {
  const history = useHistory();
  const classes = useStyles();
  const dataProvider = useDataProvider();

  // mark that inbox has been read by issuing a GET /inbox/:id request
  React.useEffect(() => {
    dataProvider.getOne('inboxes', { id });
  }, []);

  if (!record) {
    return <>No detail is available.</>;
  }

  let url: string | null = null;
  let entity = null;
  let entityId = null;
  switch (record.data.payload_type) {
    case 'App\\Models\\Order':
      entityId = record.data.payload_id;
      url = `/orders/${entityId}/show`;
      entity = `Order`;
  }

  return (
    <div className={classes.detailContainer}>
      <div className="title">{`${entity} ${entityId}`}</div>
      <div className="body">{record?.data.content}</div>
      {url && (
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={() => history.push(url)}
          className="action"
        >
          Go to {entity}
        </Button>
      )}
    </div>
  );
};

const InboxList = (props: any) => (
  <>
    <TitleBreadcrumb paths={[]} title="Inbox" />
    <List exporter={false} filters={false} sort={{ field: 'created_at', order: 'DESC' }} bulkActionButtons={false} {...props}>
      <Datagrid expand={<InboxBody />} rowClick="expand">
        <CustomDateField source="created_at" label="Date" />
        <TextField source="data.title" label="Title" />
        <FunctionField
          source="read_at"
          label=""
          render={(record: Inbox) =>
            record.read_at ? (
              ''
            ) : (
                <Icon color="primary" fontSize="small">
                  <FiberManualRecordIcon />
                </Icon>
              )
          }
        />
      </Datagrid>
    </List>
  </>
);

export default InboxList;
