import PublicHolidayList from "./PublicHolidayList";
import PublicHolidayCreate from "./PublicHolidayCreate";
import PublicHolidayEdit from "./PublicHolidayEdit";
import CalendarIcon from "@material-ui/icons/CalendarToday";

export default {
    list: PublicHolidayList,
    edit: PublicHolidayEdit,
    create: PublicHolidayCreate,
    icon: CalendarIcon
};