import React from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';
import TitleBreadcrumb from '../../../components/TitleBreadcrumb';
import ImageInput from '../../../components/ImageInput';

const CarMakeCreate = (props: any) => {
  const breadcrumb = [
    { link: '/car-makers', title: 'Car Makers' },
    { link: `/car-makers/create`, title: 'Create' },
  ];

  return (
    <>
      <TitleBreadcrumb paths={breadcrumb} />
      <Create {...props} title="Create Car Make">
        <SimpleForm>
          <TextInput source="name" fullWidth />
        </SimpleForm>
      </Create>
    </>
  );
};

export default CarMakeCreate;
