import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { Loading, useDataProvider, useNotify, useRedirect } from 'react-admin';
import { Order } from '../../types';
import { OrderStatus } from '../../utils/const';
import { canCancel, canCancelWithFee, willBeChargedFullWhenCancel } from '../../utils/order';
import { useHistory } from 'react-router-dom';
import { composeError } from '../../dataProvider';
import { get } from 'lodash';

interface Props {
  id: number;
}
const OrderCancel = ({ id }: Props) => {
  const [isCharged, setIsCharged] = React.useState<boolean>(false);
  const [note, setNote] = React.useState<string>('');
  const [loading, setLoading] = React.useState(true);
  const [enableFee, setEnableFee] = React.useState(false);
  const [chargeFull, setChargeFull] = React.useState(false);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const redirect = useRedirect();
  const history = useHistory();

  React.useEffect(() => {
    dataProvider
      .getOne('orders', { id })
      .then(({ data }: { data: Order }) => {
        setLoading(false);

        // if order cannot be cancelled, redirect to show order page
        if (!canCancel(data.status)) {
          redirect(`/orders/${id}/show`);
        }

        // can order be cancelled with Fee?
        if (canCancelWithFee(data.status)) {
          // Initialize is_charged with true
          setIsCharged(true);
          // Show text charge booking fee or not
          setEnableFee(true);
        }

        if (willBeChargedFullWhenCancel(data.status)) {
          setChargeFull(true);
          setIsCharged(true);
          setEnableFee(false);
        }
      })
      .catch((error: any) => {
        setLoading(false);
        notify('Error: order cannot be fetched.', 'warning');
      });
  }, []);

  const handleCancel = (props: any) => {
    setLoading(true);
    dataProvider
      .update('orders', {
        id,
        data: {
          status: OrderStatus.ORDER_CANCEL,
          is_charged: isCharged,
          cancellation_notes: note,
        },
      })
      .then((data: any) => {
        setLoading(false);
        notify('Order has been cancelled', 'info');
        redirect(`/orders/${id}/show`);
      })
      .catch((error: any) => {
        setLoading(false);
        notify(`Error: ${composeError(error)}`, 'warning');
      });
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Card>
      <CardContent>
        <Typography gutterBottom>Proceed to cancel this order?</Typography>
        {enableFee && (
          <Box>
            <FormControlLabel
              control={<Checkbox onChange={(e) => setIsCharged(e.target.checked)} />}
              label="Charge the Customer with Booking Fee"
              checked={isCharged}
            />
          </Box>
        )}
        {chargeFull && (
          <Box>Customer will be charged in full, please refund from stripe directly if applicable</Box>
        )}
        <Box>
          <TextField
            label="Reason (for AutoApp reference only)"
            multiline
            rows="4"
            inputProps={{ maxLength: 100 }}
            fullWidth
            onChange={(event) => setNote(event?.target.value)}
            defaultValue={note}
          />
        </Box>
      </CardContent>
      <CardActions>
        <Button variant="text" onClick={() => history.goBack()}>
          Back
        </Button>
        <Button color="primary" variant="contained" onClick={handleCancel} disabled={loading}>
          Confirm to Cancel Order
        </Button>
      </CardActions>
    </Card>
  );
};

export default OrderCancel;
