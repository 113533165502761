import React from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import { Order } from '../../types';
import UpdateOrderDate from './UpdateOrderDate';

interface Props {
  id: number;
}
const UpdateInspectionDate = (props: Props) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [order, setOrder] = React.useState<Order | undefined>();
  const { id } = props;

  React.useEffect(() => {
    dataProvider
      .getOne('orders', { id })
      .then(({ data }: { data: Order }) => {
        setOrder(data);
      })
      .catch((error: any) => {
        notify('Error: order cannot be fetched.', 'warning');
      });
  }, []);

  const handleSubmit = (date: string) =>
    dataProvider.update('orders', {
      id,
      data: {
        inspection_date: date,
      },
    });

  return (
    <UpdateOrderDate
      order={order}
      source="inspection_date"
      onSubmit={handleSubmit}
      text="Enter the new inspection date."
      onSuccessNotification="Inspection date has been updated."
      onErrorNotification="Error: Inspection date cannot be updated."
    />
  );
};

export default UpdateInspectionDate;
