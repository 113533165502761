import React from 'react';
import { Datagrid, Filter, List, TextField, TextInput, SelectInput, FunctionField } from 'react-admin';
import { Helmet } from 'react-helmet';
import CustomDateField from '../../components/CustomDateField';
import DayField from '../../components/DayField';
import ListActions from '../../components/ListActions';
import { month, dayOfWeek } from '../../utils/const';
import { get } from 'lodash';

const MyFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Title" source="search" />
    <TextInput label="Year" source="year" alwaysOn />
    <SelectInput source="month" choices={month} alwaysOn />
    <SelectInput source="dow" label="Day" choices={dayOfWeek} />
  </Filter>
);

const PublicHolidayList = (props: any) => (
  <List
    sort={{ field: 'date', order: 'DESC' }}
    exporter={false}
    bulkActionButtons={false}
    title="Holiday Management"
    actions={<ListActions permission={`public-holidays.create`} />}
    filters={<MyFilter />}
    filterDefaultValues={{
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
    }}
    {...props}
  >
    <Datagrid rowClick="edit">
      <Helmet>
        <title>Holiday Management</title>
      </Helmet>
      <TextField source="id" />
      <CustomDateField source="date" label="Date" hideTime={true} />
      <FunctionField
        label="Month"
        render={(record: any) => {
          const selected = month.find((el) => el.id === record.month);
          return get(selected, 'name');
        }}
      />
      <FunctionField
        label="Day"
        render={(record: any) => {
          const selected = dayOfWeek.find((el) => el.id === record.dow);
          return get(selected, 'name');
        }}
      />
      <TextField source="title" />
    </Datagrid>
  </List>
);

const MonthSelectInput = (props: any) => {
  const { source } = props;
  return (
    <SelectInput
      source={source}
      choices={[
        { id: 1, name: 'January' },
        { id: 2, name: 'February' },
        { id: 3, name: 'March' },
        { id: 4, name: 'April' },
        { id: 5, name: 'May' },
        { id: 6, name: 'June' },
        { id: 7, name: 'July' },
        { id: 8, name: 'Augues' },
        { id: 9, name: 'September' },
        { id: 10, name: 'October' },
        { id: 11, name: 'November' },
        { id: 12, name: 'December' },
      ]}
    />
  );
};

export default PublicHolidayList;
