import RichTextInput from 'ra-input-rich-text';
import React from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';
import TitleBreadcrumb from '../../../components/TitleBreadcrumb';

const FaqCreate = (props: any) => {
  const breadcrumb = [
    { link: '/FAQs', title: 'FAQs' },
    { link: `/FAQs/create`, title: 'Create' },
  ];

  return (
    <>
      <TitleBreadcrumb paths={breadcrumb} />
      <Create {...props} title="Create FAQ">
        <SimpleForm>
          <TextInput source="display_order" type="number" />
          <TextInput source="question" fullWidth />
          <RichTextInput source="answer" multiline fullWidth />
        </SimpleForm>
      </Create>
    </>
  );
};

export default FaqCreate;
