import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import {
  FormWithRedirect,
  Labeled,
  SaveButton,
  TextInput,
  Toolbar,
  usePermissions,
  Loading,
} from 'react-admin';
import { useForm } from 'react-final-form';
import UserForm from '../../components/UserForm';
import { Advisor, License } from '../../types';
import ImageInput from '../../components/ImageInput';

const ServiceAdvisorForm = (props: any) => {
  const [licenses, setLicenses] = React.useState<License[]>([]);
  const { permissions } = usePermissions();

  if (!permissions) {
    return <Loading />;
  }

  return (
    <FormWithRedirect
      {...props}
      render={(formProps: any) => (
        <form>
          <Grid container spacing={3}>
            <Grid item md={6}>
              <h2>Account Detail</h2>
              <UserForm />
              <h2>Licenses</h2>
              <ImageInput source="photo_license_front_url" label="Photo of Driving License (Front)" />
              <ImageInput source="photo_license_rear_url" label="Photo of Driving License (Back)" />
              <LicenseCheckbox setLicenses={setLicenses} licenses={licenses ?? []} {...formProps} />
            </Grid>
            <Grid item md={6}>
              <h2>Ambassador Detail</h2>
              <TextInput source="full_name" label="SA Full Name" fullWidth />
              <TextInput source="phone" label="Mobile Number" inputProps={{ maxLength: 8 }} fullWidth />
              <ImageInput source="photo_profile_url" label="Profile Photo" />
            </Grid>
          </Grid>
          <Toolbar>
            <Box display="flex" justifyContent="space-between" width="100%">
              {permissions('advisors.update') && (
                <MySaveButton
                  saving={formProps.saving}
                  handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  licenses={licenses}
                />
              )}
            </Box>
          </Toolbar>
        </form>
      )}
    />
  );
};

/**
 * Custom save button to modify licenses before submitted to the web
 * @param param0
 */
const MySaveButton = ({ handleSubmitWithRedirect, ...props }: any) => {
  const form = useForm();
  const { licenses = [] } = props;

  const handleClick = React.useCallback(() => {
    form.change('licenses', licenses);
    handleSubmitWithRedirect('edit');
  }, [form, props.licenses]);

  return <SaveButton {...props} handleSubmitWithRedirect={handleClick} />;
};

/**
 * Custom checkboxes to display Licenses
 */
interface LicenseChecboxProps {
  licenses: License[];
  setLicenses: (licenses: License[]) => void;
  record?: Advisor;
}
const LicenseCheckbox = ({ licenses, setLicenses, ...props }: LicenseChecboxProps) => {
  const licenseOptions = [
    { id: '1', name: '1' },
    { id: '2', name: '2' },
    { id: '2A', name: '2A' },
    { id: '2B', name: '2B' },
    { id: '3', name: '3' },
    { id: '3A', name: '3A' },
    { id: '4', name: '4' },
    { id: '4A', name: '4A' },
    { id: '5', name: '5' },
  ];

  const { record } = props;
  React.useEffect(() => setLicenses(record ? record.licenses : []), [record]);

  const handleChangeLicense = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    if (event.target.checked) {
      setLicenses([...licenses, { class: name }]);
    } else {
      setLicenses(licenses.filter((license) => license.class !== name));
    }
  };

  return (
    <Labeled label="License Classes">
      <>
        {licenseOptions.map((option) => (
          <FormControlLabel
            key={option.id}
            control={
              <Checkbox
                checked={licenses.find((license) => license.class == option.id) ? true : false}
                onChange={handleChangeLicense}
                name={option.name}
              />
            }
            label={option.name}
          />
        ))}
      </>
    </Labeled>
  );
};

export default ServiceAdvisorForm;
