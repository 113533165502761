import React, { useState, useEffect } from 'react';
import { useQuery, Loading, Error, useDataProvider, SimpleForm, Create } from 'react-admin';
import { Order, QueryResult, Product, ProductItem } from '../../types';
import LineItems from './LineItems';
import { OrderStatus } from '../../utils/const';
import RichTextInput from 'ra-input-rich-text';
import './quotation-form.scss';
import { Box, Button, TextField } from '@material-ui/core';
import { addDays, format } from 'date-fns';
import { convertJsToSql, convertSqlToJs } from '../../utils/date';
import { get } from 'lodash';

type Props = {
  id: number;
  [x: string]: any;
  isRevision?: boolean;
};

const QuotationForm: React.FC<Props> = ({ id, isRevision = false, ...props }) => {
  const { history } = props;
  const [collectionDate, setCollectionDate] = React.useState<string>(
    format(new Date(), "yyyy-MM-dd'T'HH:mm"),
  );

  // need to get list of products
  const { data: products }: QueryResult<Product[]> = useQuery({
    type: 'getList',
    resource: 'products',
    payload: {
      pagination: {
        perPage: 1000,
      },
      sort: {
        field: 'name',
        order: 'asc',
      },
    },
  });

  // Also, need to get the order's items
  const { data: originalOrder, loading, error }: QueryResult<Order> = useQuery({
    type: 'getOne',
    resource: 'orders',
    payload: { id },
  });

  const [remarks, setRemarks] = useState('');
  const [originalItems, setOriginalItems] = useState<ProductItem[] | null>(null);
  const [items, setItems] = useState<ProductItem[] | null>(null);
  const [gotLoading, setGotLoading] = useState(false);
  const [gotError, setGotError] = useState<any>();
  const dataProvider = useDataProvider();

  useEffect(() => {
    setItems(get(originalOrder, 'items'));
    setOriginalItems(get(originalOrder, 'items'));

    // if isRevision, then the resourceItems need to pull data from
    // previously agreed quotation (stored in SalesOrder) and the original
    // price (stored in Quotation)
    if (isRevision) {
      const previouslyAgreedItems: ProductItem[] | null = get(originalOrder, 'sales_order.items', null);
      const previouslyProposedItems: ProductItem[] | null = get(originalOrder, 'quotation.items', null);
      const revisedItems = previouslyAgreedItems?.map((previouslyAgreedItem) => {
        const vendorItem = previouslyProposedItems?.find(
          (previouslyProposedItem) => previouslyProposedItem.product_id === previouslyAgreedItem.product_id,
        );

        return {
          ...previouslyAgreedItem,
          unit_price: vendorItem ? vendorItem.unit_price : 0,
          total: vendorItem ? vendorItem.total : 0,
        };
      });

      if (revisedItems) {
        setItems(revisedItems);
        setOriginalItems(revisedItems);
      }
    }
  }, [originalOrder]);

  useEffect(() => {
    const resourceItems = isRevision ? 'quotation.items' : 'items';
    setItems(get(originalOrder, resourceItems));
    setOriginalItems(get(originalOrder, resourceItems));

    if (originalOrder) {
      if (originalOrder.collection_date) {
        setCollectionDate(convertSqlToJs(originalOrder?.collection_date));
      } else {
        setCollectionDate(format(new Date(), "yyyy-MM-dd'T'HH:mm"));
      }
    }
  }, [originalOrder]);

  if (loading || gotLoading) return <Loading />;
  if (error || gotError) return <Error />;
  if (!originalOrder) return null;
  if (!items) return null;
  if (!products) return null;

  // when user resets the form
  const handleResetLineItems = () => {
    setItems(originalItems);
  };

  const handleRemoveLineItem = (elementIndex: number) => () => {
    setItems(items.filter((item, i) => elementIndex !== i));
  };

  const handleAddLineItem = () => {
    const newItem: ProductItem[] = [
      {
        id: 0,
        product_id: 0,
        description: '',
        quantity: 0,
        total: 0,
        unit_price: 0,
        product: {
          id: 0,
          code: '',
          name: '',
          description: '',
          photo_url: '',
          price: 0,
        },
      },
    ];
    setItems(items.concat(newItem));
  };

  const handleLineItemChange = (elementIndex: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const lineItems = items.map((item, i) => {
      let { name, value } = event.target;
      if (elementIndex !== i) return item;

      // if user is trying to change product from dropdown, then reset the line item
      // and set the parameters of the line item to default or to Product
      if (name === 'product_id') {
        if (parseInt(value) === item.product_id) return item; // nothing change
        const selectedProduct = products.find((item) => item.id === parseInt(value));
        if (selectedProduct) {
          return {
            ...item,
            product_id: selectedProduct.id,
            description: selectedProduct?.description,
            unit_price: selectedProduct?.price,
            quantity: 1,
          };
        }
      }

      return { ...item, [name]: value };
    });
    setItems(lineItems);
  };

  const handleSaveForm = () => {
    setGotLoading(true);

    const normalPayload = {
      status: OrderStatus.ORDER_QUOTATION_START,
      quotation: {
        items,
      },
    };
    const revisionPayload = {
      revised_quotation: {
        items,
      },
    };
    const payload = isRevision ? revisionPayload : normalPayload;

    dataProvider
      .update('orders', {
        id,
        data: {
          remarks,
          collection_date: collectionDate !== '' ? convertJsToSql(collectionDate) : '',
          ...payload,
        },
      })
      .then((order: Order) => {
        setGotLoading(false);
        // show quotation
        history.push(`/orders/${id}/show/2`);
      })
      .catch((error: any) => {
        setGotError(error);
        setGotLoading(false);
      });
  };

  return (
    <>
      <LineItems
        items={items}
        products={products}
        resetHandler={handleResetLineItems}
        addHandler={handleAddLineItem}
        changeHandler={handleLineItemChange}
        deleteHandler={handleRemoveLineItem}
      />
      <div className="to-right">
        <Create {...props}>
          <SimpleForm>
            <RichTextInput
              source="remark"
              required
              multiline
              fullWidth
              onChange={(e: string) => setRemarks(e)}
            />
          </SimpleForm>
        </Create>
      </div>
      <Box className="to-right">
        <TextField
          label="Estimated Collection Date"
          type="datetime-local"
          value={collectionDate}
          style={{ width: '300px' }}
          onChange={(event) => setCollectionDate(event.target.value)}
        />
      </Box>
      <div className="to-right">
        <Button variant="contained" onClick={handleSaveForm} color="primary" disabled={gotLoading}>
          Save and Send Quotation
        </Button>
      </div>
    </>
  );
};

export default QuotationForm;
