import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import React from 'react';
import { Edit, Show, SimpleShowLayout } from 'react-admin';
import OrderGrid from '../../components/OrderGrid';
import TitleBreadcrumb from '../../components/TitleBreadcrumb';
import CarList from './CarList';
import CustomerForm from './CustomerForm';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props;

  return <div>{value === index && <Box>{children}</Box>}</div>;
}

const CustomerEdit = (props: any) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const breadcrumbPaths = [
    { link: '/customers', title: 'Customers' },
    { link: `/customer/${props.id}`, title: props.id },
  ];

  return (
    <>
      <TitleBreadcrumb paths={breadcrumbPaths} />
      <Paper style={{ marginTop: 20 }}>
        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          aria-label="disabled tabs example"
        >
          <Tab label="Customer Detail" />
          <Tab label="Cars" />
          <Tab label="Orders" />
        </Tabs>
      </Paper>
      <TabPanel value={value} index={0}>
        <Edit {...props} undoable={false}>
          <CustomerForm />
        </Edit>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Show style={{ marginTop: '1em' }} actions={<></>} {...props}>
          <SimpleShowLayout>
            <CarList {...props} />
          </SimpleShowLayout>
        </Show>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Show style={{ marginTop: '1em' }} actions={<></>} {...props}>
          <SimpleShowLayout>
            <OrderGrid target="customer_id" emptyMessage="Customer has not made any order yet." />
          </SimpleShowLayout>
        </Show>
      </TabPanel>
    </>
  );
};

export default CustomerEdit;
