import React from "react";
import { Route } from "react-router-dom";
import BookingFee from "./pages/masterData/BookingFee";
import TermCondition from "./pages/masterData/TermCondition";
import PrivacyPolicy from "./pages/masterData/PrivacyPolicy";
import GST from "./pages/masterData/GST";
import Reports from "./pages/reports/Reports";
import InitICS from "./pages/publicHoliday/InitICS";

export default [
  <Route exact path="/booking-fee" render={() => <BookingFee />} />,
  <Route exact path="/term-condition" render={() => <TermCondition />} />,
  <Route exact path="/privacy-policy" render={() => <PrivacyPolicy />} />,
  <Route exact path="/gst" render={() => <GST />} />,
  <Route exact path="/reports" render={() => <Reports />} />,
  <Route exact path="/init-year" render={() => <InitICS />} />,
];
