import { makeStyles } from '@material-ui/core';
import React from 'react';
import { Edit, Show, Tab, TabbedShowLayout } from 'react-admin';
import OrderGrid from '../../components/OrderGrid';
import TitleBreadcrumb from '../../components/TitleBreadcrumb';
import ServiceAdvisorForm from './ServiceAdvisorForm';

const useStyle = makeStyles({
  noshadow: {
    '& .ra-field': {
      marginBottom: '20px',
      '& .MuiPaper-rounded': {
        boxShadow: 'none',
      },
    },
  },
});

const ServiceAdvisorEdit = (props: any) => {
  const classes = useStyle();
  const { id } = props;
  const breadcrumb = [
    { link: '/advisors', title: 'Ambassadors' },
    { link: `/advisors/${id}`, title: id },
  ];

  return (
    <>
      <TitleBreadcrumb paths={breadcrumb} />
      <Show {...props} actions={<></>} className={classes.noshadow} title=" ">
        <TabbedShowLayout>
          <Tab label="SA Details">
            <Edit undoable={false} {...props}>
              <ServiceAdvisorForm {...props} />
            </Edit>
          </Tab>
          <Tab label="Orders">
            <OrderGrid target="advisor_id" emptyMessage="Advisor has not been assigned to any order yet." />
          </Tab>
        </TabbedShowLayout>
      </Show>
    </>
  );
};

export default ServiceAdvisorEdit;
