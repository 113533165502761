import React from 'react';
import { Product } from '../../types';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

type Props = {
  products: Product[];
  changeHandler: (e: any) => void;
  selectedValue?: number;
  [x: string]: any;
};

/**
 * Create ProductDropdown
 * @param param0
 */
const LineItemDropdown: React.FC<Props> = (props) => {
  const { products, selectedValue, changeHandler, ...rest } = props;
  return (
    <FormControl>
      <Select onChange={changeHandler} value={`${selectedValue}`} name="product_id" {...rest}>
        {products
          .sort((a: Product, b: Product) => (a.code > b.code ? 1 : -1))
          .map((product: Product) => (
            <MenuItem value={product.id} key={product.id}>
              {product.code}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default LineItemDropdown;
