import React from 'react';
import { Car } from '../../types';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';
import { convertSqlDateToLocale } from '../../utils/date';

const useStyles = makeStyles({
  root: {
    border: '1px solid #ccc',
    borderRadius: 3,
    padding: '10px',
    width: '400px',
    display: 'flex',
    marginRight: '5px',
  },
  photoContainer: {
    width: '100px',
    marginRight: '5px',
  },
  detailContainer: {
    color: 'inherit',
    fontSize: 'inherit',
    marginLeft: '5px',
  },
  photo: {
    width: '100px',
    objectFit: 'cover',
    height: '100px',
  },
});

interface Record {}

interface Props {
  className?: string;
  record?: any;
  resource?: string;
  basePath?: string;
  label?: string;
  source: any;
  clickable?: boolean;
}

const CarField = ({ source, record, clickable = true, ...props }: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const car: Car = get(record, source, null);

  if (!car) {
    return <>-</>;
  }

  const handleClick = () => {
    if (car) {
      return clickable ? history.push(`/customer-cars/${car?.id}`) : '';
    }
  };

  if (!record || !car) {
    return <span>-</span>;
  }

  return (
    <Box className={classes.root} onClick={handleClick} style={{ cursor: 'pointer' }}>
      <Box className={classes.photoContainer}>
        {car.photos.length > 0 && (
          <img className={classes.photo} src={car.photos[0].url} alt={car.licence_plate_number} />
        )}
      </Box>
      <Box className={classes.detailContainer}>
        <div className="maker">{car.model.maker.name}</div>
        <div className="model">{car.model.name}</div>

        <div className="license">
          <strong>Plate:</strong> {car.licence_plate_number}
        </div>
        <div className="registration_date">
          <strong>Registration:</strong>
          {car.registration_date && <>{convertSqlDateToLocale(car.registration_date)}</>}
        </div>
        <div className="transmission">
          <strong>Transmission:</strong> {car.transmission}
        </div>
        <div className="notes">
          <strong>Owner's Note:</strong> {car.notes}
        </div>
      </Box>
    </Box>
  );
};

CarField.defaultProps = {
  addLabel: true,
};

export default CarField;
