import React from 'react';
import { Edit, SimpleForm, TextInput, DateInput } from 'react-admin';
import TitleBreadcrumb from '../../components/TitleBreadcrumb';
import SaveDeleteToolbar from '../../components/SaveDeleteToolbar';

const PublicHolidayEdit = (props: any) => {
  console.log(props);
  const { id } = props;
  const breadcrumb = [
    { link: '/public-holidays', title: 'Holidays' },
    { link: `/public-holidays/${id}`, title: id },
  ];

  return (
    <>
      <TitleBreadcrumb paths={breadcrumb} />
      <Edit undoable={false} {...props} title="Edit Holiday">
        <SimpleForm
          toolbar={
            <SaveDeleteToolbar
              confirmDeleteContent={`Are you sure to delete this?`}
              savePermission={`public-holidays.update`}
              deletePermission={`public-holidays.delete`}
            />
          }
        >
          <TextInput source="title" />
          <DateInput source="date" />
        </SimpleForm>
      </Edit>
    </>
  );
};

export default PublicHolidayEdit;
