import React from 'react';
import { Create, RadioButtonGroupInput, SimpleForm, TextInput } from 'react-admin';
import TitleBreadcrumb from '../../../components/TitleBreadcrumb';
import ImageInput from '../../../components/ImageInput';

const ServiceCreate = (props: any) => {
  const breadcrumb = [
    { link: '/products', title: 'Products & Services' },
    { link: `/products/create`, title: 'Create' },
  ];

  return (
    <>
      <TitleBreadcrumb paths={breadcrumb} />
      <Create {...props} title="Create Service">
        <SimpleForm>
          <TextInput source="code" fullWidth />
          <TextInput source="name" fullWidth />
          <TextInput source="price" type="number" fullWidth />
          <TextInput source="display_order" type="number" fullWidth />
          <ImageInput source="photo_url" label="Photo" />
          <TextInput source="description" fullWidth multiline />
          {/* <RadioButtonGroupInput
            source="status"
            choices={[
              { id: 'active', name: 'Active' },
              { id: 'inactive', name: 'Inactive' },
            ]}
            value="active"
          /> */}
        </SimpleForm>
      </Create>
    </>
  );
};

export default ServiceCreate;
