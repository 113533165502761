import { makeStyles, Theme } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles/withStyles';

const styles: Styles<Theme, any> = {
  leftCol: {
    display: 'inline-block',
    width: 'calc(50% - 10px)',
    marginRight: '20px',
    '& div': {
      width: '100%',
    },
  },
  rightCol: {
    display: 'inline-block',
    width: 'calc(50% - 10px)',
    '& div': {
      width: '100%',
    },
  },

  tabbedForm: {
    padding: '0px',
  },

  topToolbar: {
    '& button': {
      marginLeft: '5px',
    },
  },
};

export const useStyle = makeStyles(styles);
