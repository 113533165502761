import React from 'react';
import { TopToolbar, CreateButton, usePermissions } from 'react-admin';

interface Props {
  permission?: string;
  [x: string]: any;
}

const ListActions = (props: Props) => {
  const { basePath, filters } = props;
  const { resource, showFilter, displayedFilters, filterValues } = props;
  const { permissions } = usePermissions();
  const { permission } = props;

  return (
    <TopToolbar {...props}>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}

      {permissions && permissions(permission) && <CreateButton basePath={basePath} />}
    </TopToolbar>
  );
};

export default ListActions;
