import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const classes = makeStyles({
  label: {
    fontWeight: 'bold',
    fontSize: '0.8rem',
    margin: '13px 0 5px 0',
    color: '#999',
  },
});

const Label = ({ value }: { value: string }) => <div className={classes().label}>{value}</div>;

export default Label;
