import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import { OrderStatus } from '../../utils/const';
import { getReport } from '../../utils/tools';

const statusDescription = [
  { key: OrderStatus.ORDER_NEW, value: 'New Orders' },
  { key: OrderStatus.ORDER_ASSIGNMENT_SUCCESS, value: 'Confirmed Orders' },
  { key: OrderStatus.ORDER_INSPECTION_STARTED, value: 'Ready for Inspection ' },
  { key: OrderStatus.ORDER_INSPECTION_CAR_WITH_SA, value: 'Finished Inspection' },
  { key: OrderStatus.ORDER_INSPECTION_CAR_WITH_WO, value: "Car's Arrived at the Workshop" },
  { key: OrderStatus.ORDER_QUOTATION_START, value: 'Waiting for Quotation Acceptance' },
  { key: OrderStatus.ORDER_QUOTATION_ACCEPTED, value: "Quotation's Been Accepted" },
  { key: OrderStatus.ORDER_WORK_STARTED, value: 'Car is Being Repaired' },
  { key: OrderStatus.ORDER_WORK_SUCCESS, value: 'Finished Repair' },
  { key: OrderStatus.ORDER_RETURN_CAR_WITH_SA, value: 'Car is Being Returned to Customer' },
];

const workshopStatusDescription = [
  { key: OrderStatus.ORDER_ASSIGNMENT_SUCCESS, value: 'Confirmed Orders' },
  { key: OrderStatus.ORDER_INSPECTION_CAR_WITH_WO, value: "Car's Arrived at the Workshop" },
  { key: OrderStatus.ORDER_QUOTATION_START, value: 'Waiting for Quotation Acceptance' },
  { key: OrderStatus.ORDER_QUOTATION_ACCEPTED, value: "Quotation's Been Accepted" },
  { key: OrderStatus.ORDER_WORK_STARTED, value: 'Car is Being Repaired' },
  { key: OrderStatus.ORDER_WORK_SUCCESS, value: 'Finished Repair' },
];

const CurrentJob = ({ permissions }: any) => {
  const [currentJobs, setCurrentJobs] = React.useState<any>([]);
  const [isLoading, setIsLoading] = React.useState(true);

  const getCurrentJobs = () => {
    getReport('current-orders', '1970-01-01', '1970-01-01')
      .then(({ json }) => setCurrentJobs(json))
      .catch((error: any) => console.log(error))
      .finally(() => setIsLoading(false));
  };

  React.useEffect(() => {
    getCurrentJobs();
  }, []);

  if (!currentJobs || currentJobs.length == 0) {
    return <div>No active orders at the moment</div>;
  }

  const findCount = (key: string) => {
    const index = currentJobs.findIndex((el: any) => el.status == key);
    return index < 0 ? 0 : currentJobs[index].count;
  };

  // if permissions contains settings_read, that means this user is an admin
  const status = permissions(`settings.read`) ? statusDescription : workshopStatusDescription;

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ fontWeight: 'bold' }}>Current Jobs</TableCell>
            <TableCell style={{ fontWeight: 'bold' }} align="right">
              #
            </TableCell>
            {/* <TableCell style={{ fontWeight: 'bold' }} align="left">
              Order Status
            </TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {status.map((row: { key: string; value: string }) => (
            <TableRow key={row.key}>
              <TableCell component="th" scope="row">
                {row.value}
              </TableCell>
              <TableCell align="right">{findCount(row.key)}</TableCell>
              {/* <TableCell align="left" style={{ fontSize: 'smaller' }}>
                {row.key}
              </TableCell> */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CurrentJob;
