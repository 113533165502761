import React from 'react';
import { Create, TextInput, SimpleForm } from 'react-admin';
import TitleBreadcrumb from '../../components/TitleBreadcrumb';

const AdminCreate = (props: any) => {
  const breadcrumb = [
    { title: 'Admins', link: '/admins' },
    { title: 'Create', link: '/admins/create' },
  ];

  return (
    <>
      <TitleBreadcrumb paths={breadcrumb} />
      <Create {...props}>
        <SimpleForm>
          <TextInput source="full_name" />
          <TextInput source="email" type="email" />
        </SimpleForm>
      </Create>
    </>
  );
};

export default AdminCreate;
