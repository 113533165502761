import React from 'react';
import { Order } from '../../types';
import { get } from 'lodash';

interface Props {
  source: string;
  record?: Order;
  label?: string;
  hideTime?: boolean;
}

const CustomDateField = ({ source, record, hideTime = false }: Props) => {
  const date = get(record, source);
  if (!date) {
    return <>-</>;
  }

  //change format YYYY-MM-DD HH:MM:SS to universal support YYYY/MM/DD HH:MM:SS
  const newDate = new Date(date.replace(/-/g, '/'));

  if (hideTime) {
    return <div>{newDate.toLocaleString([], { year: 'numeric', month: 'numeric', day: 'numeric' })}</div>;
  }

  return <div>{newDate.toLocaleString()}</div>;
};

CustomDateField.defaultProps = {
  addLabel: true,
};

export default CustomDateField;
