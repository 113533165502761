import React, { FC, useEffect, useState } from "react";
import { FieldProps, Customer, Order } from "../../types";
import { useDataProvider, Error } from "react-admin";
import LinearProgress from "@material-ui/core/LinearProgress";

const TotalCurJobField: FC<FieldProps<Customer>> = ({ record }) => {
    const dataProvider = useDataProvider();
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const notCurrent = ['ORDER_RATED', 'ORDER_CANCEL', 'ORDER_CANCEL_UNPAID', 'ORDER_CANCEL_PAID', 'ORDER_QUOTATION_EXPIRED', 'ORDER_QUOTATION_EXPIRED_UNPAID', 'ORDER_QUOTATION_EXPIRED_PAID', 'ORDER_RETURN_CAR_SUCCESS'];

    useEffect(() => {
        dataProvider
            .getList("orders", { filter: { advisor_id: record?.id } })
            .then(({ total, data }: any) => {
                const current = data.filter((obj: Order) => !notCurrent.includes(obj.status));
                setTotal(current.length);
                setLoading(false);
            })
            .catch((error: any) => {
                setError(error);
                setLoading(false);
            });
    }, []);

    if (loading) return <LinearProgress />;
    if (error) return <Error />;
    return <span>{total}</span>;
};
TotalCurJobField.defaultProps = { label: "Current Job(s)" };

export default TotalCurJobField;
