import CarMakeList from "./CarMakeList";
import CarMakeCreate from "./CarMakeCreate";
import CarMakeEdit from "./CarMakeEdit";
import CarIcon from "@material-ui/icons/DirectionsCar";

export default {
  list: CarMakeList,
  edit: CarMakeEdit,
  create: CarMakeCreate,
  icon: CarIcon
};
