import React from 'react';
import { ImageField, ImageInput } from 'react-admin';
import { useField } from 'react-final-form';

interface Props {
  source: string;
  [x: string]: any;
}
const MyImageInput = ({ source, ...props }: Props) => {
  const {
    input: { onChange, value },
  } = useField(source);

  return (
    <>
      <ImageInput source={`${source}-file`} {...props}>
        <MyImageField source="src" onChange={(val: string) => onChange(val)} />
      </ImageInput>
      <img src={value} alt={source} style={{maxWidth: "300px", maxHeight: "300px"}}/>
    </>
  );
};

interface MyImageFieldProps {
  source: string;
  record?: {
    rawFile?: File;
    [x: string]: any;
  };
  className?: string;
  onChange: (val: string) => void;
}
const getBlobFromUrl = async (url: string) => {
  let blob = await fetch(url).then((r) => r.blob());
  var reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      var base64data = reader.result;
      resolve(base64data);
    };
  });
};

const MyImageField = (props: MyImageFieldProps) => {
  const { source, record, onChange } = props;
  const url = record ? record[source] : '';

  React.useEffect(() => {
    getBlobFromUrl(url).then((val: any) => {
      onChange(val);
    });
  }, [url]);

  // return <ImageField {...props} />;
  return <></>;
};

export default MyImageInput;
