import React from 'react';
import {
  Datagrid,
  EmailField,
  Filter,
  List,
  TextField,
  TextInput,
  CreateButton,
  TopToolbar,
  usePermissions,
} from 'react-admin';
import { Helmet } from 'react-helmet';
import IdInput from '../../components/IdInput';
import StatusField from '../../components/StatusField';
import UserStatusInput from '../../components/UserStatusInput';
import ListActions from '../../components/ListActions';

const WorkshopFilter = (props: any) => (
  <Filter {...props}>
    <IdInput label="ID" source="id" />
    <TextInput label="Name and Phone" source="search" />
    <UserStatusInput label="Status" source="user.status" />
    <TextInput label="Email" source="user.email" />
  </Filter>
);

const WorkshopList = (props: any) => {
  const { permissions } = usePermissions();

  return (
    <List
      exporter={false}
      filters={<WorkshopFilter />}
      bulkActionButtons={false}
      actions={<ListActions {...props} permission="workshops.create" />}
      {...props}
    >
      <Datagrid rowClick="edit">
        <Helmet>
          <title>Workshops</title>
        </Helmet>
        <TextField source="id" label="Workshop ID" />
        <EmailField source="user.email" label="Login Email" />
        <TextField source="company_name" />
        <TextField source="name" label="Workshop Name" />
        <TextField source="owner_phone" label="Owner's Phone" />
        <StatusField source="user.status" />
      </Datagrid>
    </List>
  );
};

export default WorkshopList;
