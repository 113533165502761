import React from 'react';
import { useStyle } from '../../hooks/style';
import { TextField, SimpleShowLayout, NumberField, ImageField } from 'react-admin';
import { Order } from '../../types';
import AddressField from '../../components/AddressField';
import InspectionDiagram from '../../components/InspectionDiagram';
import CustomDateField from '../../components/CustomDateField';
import { SimpleDialog } from '../../components/InspectionDiagram/GridListPicture';
import { get } from 'lodash';

interface Props {
  record?: Order;
}

const InspectionShow: React.FC<Props> = (props) => {
  const { record } = props;
  const [selectedValue, setSelectedValue] = React.useState('');
  const [open, setOpen] = React.useState(false);

  const handleOpen = (val: string) => {
    setSelectedValue(val);
    setOpen(true);
  };

  return (
    <SimpleShowLayout record={record}>
      <InspectionDiagram {...props} source="issues" />
    </SimpleShowLayout>
  );
};

interface ImageWrapperProps {
  source: string;
  record?: Order;
  label: string;
  onClick(image: string): void;
}

const ImageView = (props: ImageWrapperProps) => {
  const image = get(props.record, props.source);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', marginTop: 10 }}>
      <label className="MuiFormLabel-root  MuiInputLabel-shrink" data-shrink="true">
        <span>{props.label}</span>
      </label>
      <img
        style={{ maxWidth: 200, cursor: 'pointer' }}
        alt={image}
        src={image}
        onClick={() => props.onClick(image)}
      />
    </div>
  );
};

export default InspectionShow;
