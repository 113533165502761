import React from 'react';
import LineItem from './LineItem';
import { Product, ProductItem } from '../../types';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Button from '@material-ui/core/Button';

interface LineItemProps {
  items: ProductItem[];
  resetHandler: () => void;
  addHandler: () => void;
  deleteHandler: (v: number) => () => void;
  changeHandler: (v: number) => (e: React.ChangeEvent<HTMLInputElement>) => void;
  products: Product[];
}

const LineItems: React.FC<LineItemProps> = (props) => {
  const { items, products } = props;
  const { resetHandler, changeHandler, addHandler, deleteHandler } = props;

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow key="header">
              <TableCell>#</TableCell>
              <TableCell>Product Code</TableCell>
              <TableCell>Description</TableCell>
              <TableCell align="right">Qty</TableCell>
              <TableCell align="right">Unit Price</TableCell>
              <TableCell align="right">Total</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items &&
              items.map((item, index) => {
                return (
                  <LineItem
                    key={index}
                    index={index}
                    products={products}
                    selectedProductId={item.product_id}
                    quantity={item.quantity}
                    unit_price={item.unit_price}
                    description={item.description}
                    changeHandler={changeHandler(index)}
                    deleteHandler={deleteHandler(index)}
                  />
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="actions" style={{ justifyContent: 'flex-start' }}>
        <Button variant="contained" onClick={addHandler} style={{ marginRight: '10px' }}>
          Add Item
        </Button>
        <Button variant="contained" color="secondary" onClick={resetHandler}>
          Reset
        </Button>
      </div>
    </>
  );
};

export default LineItems;
