export const getRequiredValidation = (values: any, requiredPropNames: string[]) => {
  const errors: any = {};
  for (let index in requiredPropNames) {
    const propName = requiredPropNames[index];
    if (!values[propName]) {
      errors[propName] = [`${propName} is required`];
    }
  }
  return errors;
};
