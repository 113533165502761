import React from 'react';
import { Create, NumberInput, SimpleForm, TextInput } from 'react-admin';
import TitleBreadcrumb from '../../components/TitleBreadcrumb';

interface Options {
  id: object;
  name: string;
}

const WorkshopCreate = (props: any) => {
  const breadcrumb = [
    { title: 'Workshops', link: '/workshops' },
    { title: 'Create', link: '/workshops/create' },
  ];

  return (
    <>
      <TitleBreadcrumb paths={breadcrumb} />
      <Create {...props}>
        <SimpleForm>
          <TextInput source="user.email" type="email" label="Email for Login" />
          <TextInput source="name" label="Workshop Name" />
          <NumberInput
            source="markup_price_percentage"
            label="Markup Percentage"
            inputProps={{ maxLength: 2 }}
          />
        </SimpleForm>
      </Create>
    </>
  );
};

export default WorkshopCreate;
