import queryString from 'query-string';
import React from 'react';
import { OrderActions } from '../../utils/const';
import AssignOrder from './AssignOrder';
import QuotationForm from './QuotationForm';
import CancelOrder from './CancelOrder';
import UpdateCollectionDate from './UpdateCollectionDate';
import { Loading } from 'react-admin';
import WorkDone from './WorkDone';
import UpdateInspectionDate from './UpdateInspectionDate';

export const OrderEdit = (props: any) => {
  const { location, id, history } = props;
  const search = queryString.parse(location.search);
  const { action } = search;

  if (!action) {
    return <Loading />;
  }

  switch (action) {
    case OrderActions.ACCEPT:
      return <AssignOrder {...props} />;
    case OrderActions.CREATE_OFFER:
      return <QuotationForm {...props} />;
    case OrderActions.CANCEL:
      return <CancelOrder {...props} />;
    case OrderActions.UPDATE_COLLECTION_DATE:
      return <UpdateCollectionDate {...props} />;
    case OrderActions.WORK_DONE:
      return <WorkDone {...props} />;
    case OrderActions.REVISE_QUOTATION:
      return <QuotationForm isRevision={true} {...props} />;
    case OrderActions.UPDATE_INSPECTION_DATE:
      return <UpdateInspectionDate {...props} />;
    default:
      history.go(`/orders/${id}/show`);
  }
};
