import React from 'react';

interface Props {
  value: number;
}

const MoneyFormatter:React.FC<Props> = ({value}) => {
  const moneyFormatter = new Intl.NumberFormat('en-SG', {
    style: 'currency',
    currency: 'SGD',
    minimumFractionDigits: 2,
  });


  return (
    <>
      {/*
        // @ts-ignore */}
      {moneyFormatter.format(value)}
    </>
  );
}

export default MoneyFormatter;