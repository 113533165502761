import React, { useCallback } from 'react';
import { Edit, ReferenceInput, SaveButton, SelectInput, SimpleForm, Toolbar } from 'react-admin';
import { useForm } from 'react-final-form';
import { OrderStatus } from '../../utils/const';
import { canReassignAdvisor, canReassignWorkshop } from '../../utils/order';
import { get } from 'lodash';

/**
 * A form to assign order to any workshop or advisor.
 *
 * @param props
 */
const AcceptOrderForm = (props: any) => {
  return (
    <Edit undoable={false} {...props} title="Assign Workshop and Ambassador">
      <CustomSimpleForm {...props} />
    </Edit>
  );
};

/**
 * Save Button to accept order
 * After saving, the page will be redirected to 'show'
 *
 * @param param0
 */
const SaveAcceptOrderButton = ({ handleSubmitWithRedirect, ...props }: any) => {
  const form = useForm();
  const handleClick = useCallback(() => {
    // order status can only be changed if it's a new order
    const formState = form.getState();
    const status = get(formState, 'values.status');
    if (status === OrderStatus.ORDER_NEW) {
      form.change('status', OrderStatus.ORDER_ASSIGNMENT_SUCCESS);
    }

    handleSubmitWithRedirect('show');
  }, [form, handleSubmitWithRedirect]);

  return <SaveButton {...props} handleSubmitWithRedirect={handleClick} />;
};

/**
 * Toolbar action buttons
 * @param props
 */
const PostCreateToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveAcceptOrderButton label="Assign" />
  </Toolbar>
);

const CustomSimpleForm = (props: any) => {
  const { record } = props;
  console.log(props);

  return (
    <SimpleForm toolbar={<PostCreateToolbar />} {...props}>
      <ReferenceInput
        source="workshop_id"
        reference="workshops"
        label="Assign Workshop"
        disabled={!canReassignWorkshop(record.status) ? true : false}
        perPage={1000}
        sort={{ field: 'company_name', order: 'ASC' }}
        filter={{ 'user.status': 'active' }}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        source="advisor_id"
        reference="advisors"
        label="Assign Ambassador"
        disabled={!canReassignAdvisor(record.status) ? true : false}
        perPage={1000}
        sort={{ field: 'full_name', order: 'ASC' }}
        filter={{ 'user.status': 'active' }}
      >
        <SelectInput optionText="full_name" />
      </ReferenceInput>
    </SimpleForm>
  );
};

export default AcceptOrderForm;
