import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Title } from "react-admin";
export default (props: any) => (
  <Card>
    <Title title="GST" />
    <CardContent>
      <Grid container spacing={1} style={{ alignItems: "center" }}>
        <Grid item xs={8} sm={3} md={2}>
          <Typography variant="subtitle2" gutterBottom>
            Current GST rate
          </Typography>
          <Typography variant="h5" gutterBottom>
            7%
          </Typography>
        </Grid>
        <Grid item xs={8} sm={4} md={2}>
          <TextField
            fullWidth
            id="new_booking_fee"
            label="New GST Rate"
            variant="filled"
          />
        </Grid>
        <Grid
          style={{ display: "flex", alignItems: "center" }}
          item
          xs={4}
          sm={2}
        >
          <Button
            variant="contained"
            color="primary"
            style={{ marginLeft: "10px" }}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);
