import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { get } from 'lodash';
import React from 'react';
import { Labeled, NumberField, TextField, usePermissions, Loading } from 'react-admin';
import AddressField from '../../components/AddressField';
import CarField from '../../components/CarField';
import CustomDateField from '../../components/CustomDateField';
import { ImageDialog } from '../../components/ImageDialog';
import { ProfileField } from '../../components/ProfileField';
import { Order } from '../../types';
import StatusField from './../../components/StatusField';
import RatingField from '../../components/RatingField';

const useStyles = makeStyles({
  root: {
    '& h2': {
      margin: '30px 0 10px 0',
    },
  },
  orderDetails: {
    border: '1px solid #ddd',
    borderRadius: '3px',
    margin: '20px',
    minWidth: '400px',
    backgroundColor: '#eee',
    '& > div': {
      display: 'block',
    },
  },
  right: {
    '& > div': {
      display: 'block',
    },
  },
  signatures: {
    '& > button': {
      display: 'block',
      margin: '3px 0',
    },
  },
});

interface Props {
  record?: Order;
}

const Detail: React.FC<Props> = (props) => {
  const { permissions: permission } = usePermissions();
  const { record } = props;
  const classes = useStyles();
  const [showImage, setShowImage] = React.useState('');

  if (!permission) {
    return <Loading />;
  }

  return (
    <Grid
      container
      spacing={5}
      direction="row"
      justify="flex-start"
      alignItems="flex-start"
      className={classes.root}
    >
      <ImageDialog open={showImage !== ''} selectedValue={showImage} onClose={() => setShowImage('')} />
      <Grid item xs={4} className={classes.orderDetails}>
        <Labeled label="Created Date">
          <CustomDateField source="created_at" record={record} />
        </Labeled>
        <Labeled label="Status">
          <StatusField record={record} label="Status" source="status" />
        </Labeled>
        <Labeled label="Order Id">
          <TextField record={record} source="id" label="Order ID" />
        </Labeled>
        {permission(`orders.read.cancellation_notes`) && record?.cancellation_notes && (
          <Labeled label="Cancellation Notes">
            <TextField record={record} source="cancellation_notes" />
          </Labeled>
        )}
        {permission(`customers.read`) && (
          <Labeled label="Customer Details">
            <ProfileField record={record} source="customer" />
          </Labeled>
        )}
        <Labeled label="Workshop">
          <ProfileField record={record} source="workshop" clickable={permission(`workshops.read`)} />
        </Labeled>
        <Labeled label="Ambassador">
          <ProfileField
            record={record}
            source="advisor"
            label="Ambassador"
            clickable={permission(`advisors.read`)}
          />
        </Labeled>

        <Labeled label="Estimated Ready Date">
          <CustomDateField source="collection_date" record={record} />
        </Labeled>

        <div className={classes.signatures}>
          <Signature
            text="Inspection Sign Off (Customer)"
            record={record}
            source="customer_give_signature_url"
          />
          <Signature text="Workshop Sign Off" record={record} source="workshop_accept_signature_url" />
          <Signature
            text="Service complete Sign Off (Workshop)"
            record={record}
            source="workshop_give_signature_url"
          />
          <Signature
            text="Service complete Sign Off (SA)"
            record={record}
            source="advisor_accept_signature_url"
          />
          <Signature
            text="Job Complete Sign Off (Customer)"
            record={record}
            source="customer_accept_signature_url"
          />
        </div>
      </Grid>

      <Grid item xs={4} className={classes.right}>
        <h2>Car Details</h2>
        <CarField record={record} source="customer_car" clickable={permission(`customer_cars.read`)} />
        <Labeled label="Registration date per inspection">
          <CustomDateField record={record} source="registration_date" hideTime={true} />
        </Labeled>
        <Labeled label="Mileage per inspection (KM)">
          <NumberField record={record} source="mileage" />
        </Labeled>
        <Labeled label="Cash card per inspection ($)">
          <NumberField record={record} source="cash_card" />
        </Labeled>
        <Labeled label="Fuel level per inspection (%)">
          <div>{get(record, 'fuel_level', 0) * 100}%</div>
        </Labeled>
        <Labeled label="VIN per inspection">
          <ImageView
            record={record}
            label="VIN"
            onClick={() => setShowImage(get(record, 'vin_url', ''))}
            source="vin_url"
          />
        </Labeled>
        <Labeled label="Registration no. per inspection">
          <ImageView
            record={record}
            label="Registration No"
            source="registration_number_url"
            onClick={() => setShowImage(get(record, 'registration_number_url', ''))}
          />
        </Labeled>

        <h2>Services</h2>
        <ol>
          {record?.items?.map((item) => (
            <li key={item.id}>{item.description}</li>
          ))}
        </ol>
        <Labeled label="Others">
          <TextField record={record} source="notes" component="pre" />
        </Labeled>

        <h2>Pickup Details</h2>
        <Labeled label="Inspection Date">
          <CustomDateField record={record} source="inspection_date" label="a" />
        </Labeled>
        <Labeled label="Inspection Address">
          <AddressField record={record} source="inspection_address" label="b" />
        </Labeled>

        <h2>Delivery Details</h2>
        <Labeled label="Delivery Date">
          <CustomDateField record={record} source="collection_date" label="a" />
        </Labeled>
        <Labeled label="Delivery Address">
          <AddressField record={record} source="collection_address" label="b" />
        </Labeled>

        <h2>Other Info</h2>
        <Labeled label="Rating">
          <RatingField record={record} source="rating" />
        </Labeled>
        <Labeled label="Feedback">
          <TextField record={record} source="feedback" />
        </Labeled>
      </Grid>
    </Grid>
  );
};
interface ImageWrapperProps {
  source: string;
  record?: Order;
  label: string;
  onClick(image: string): void;
}
const ImageView = (props: ImageWrapperProps) => {
  const image = get(props.record, props.source);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', marginTop: 10 }}>
      <img
        style={{ maxWidth: 200, cursor: 'pointer' }}
        alt={image}
        src={image}
        onClick={() => props.onClick(image)}
      />
    </div>
  );
};

type SignatureProps = {
  text: string;
  record: any;
  source: string;
};
const Signature = ({ text, record, source }: SignatureProps) => {
  const [showImage, setShowImage] = React.useState('');
  const url: string = get(record, source, '');

  // if there's no picture URL, show a disabled text
  if (!url || url === '') {
    return (
      <Button variant="outlined" disabled size="small">
        {text}
      </Button>
    );
  }

  return (
    <div style={{ margin: '5px 0' }}>
      <Dialog open={showImage !== ''}>
        <DialogTitle onClose={() => setShowImage('')}>{text}</DialogTitle>
        <DialogContent>
          <img src={url} />
        </DialogContent>
      </Dialog>
      <Button variant="outlined" size="small" onClick={() => setShowImage(url)}>
        {text}
      </Button>
    </div>
  );
};

const styles: any = (theme: any) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props: any) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export default Detail;
