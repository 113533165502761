import React from 'react';
import { Edit } from 'react-admin';
import TitleBreadcrumb from '../../components/TitleBreadcrumb';
import PromoCodeForm from './PromoCodeForm';

const PromoCodeEdit = (props: any) => {
  const { id } = props;
  const breadcrumb = [
    { link: '/promo-codes', title: 'Promo Codes' },
    { link: `/promo-codes/${id}`, title: id },
  ];

  return (
    <>
      <TitleBreadcrumb paths={breadcrumb} />
      <Edit undoable={false} {...props} title="Edit Promo Code">
        <PromoCodeForm mode="edit" />
      </Edit>
    </>
  );
};

export default PromoCodeEdit;
