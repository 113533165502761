import React from 'react';
import { Create, SimpleForm, TextInput, DateInput } from 'react-admin';
import TitleBreadcrumb from '../../components/TitleBreadcrumb';

const PublicHolidayCreate = (props: any) => {
  const breadcrumb = [
    { link: '/public-holidays', title: 'Holidays' },
    { link: `/public-holidays/create`, title: 'Create' },
  ];

  return (
    <>
      <TitleBreadcrumb paths={breadcrumb} />
      <Create {...props} title="Create Holiday">
        <SimpleForm>
          <TextInput source="title" fullWidth />
          <DateInput source="date" fullwidth />
        </SimpleForm>
      </Create>
    </>
  );
};

export default PublicHolidayCreate;
