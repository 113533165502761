import React from 'react';
import { Edit, SaveButton, SimpleForm, TextInput, Toolbar, NumberInput, usePermissions } from 'react-admin';
import TitleBreadcrumb from '../../components/TitleBreadcrumb';
import RichTextInput from '../../components/RichTextInput';

const EditToolbar = (props: any) => {
  const { permissions } = usePermissions();
  return <Toolbar {...props}>{permissions && permissions('settings.update') && <SaveButton />}</Toolbar>;
};

const SettingEdit = (props: any) => {
  const { id } = props;
  const breadcrumb = [
    { link: '/settings', title: 'Settings' },
    { link: `/settings/${id}`, title: id },
  ];

  return (
    <>
      <TitleBreadcrumb paths={breadcrumb} />
      <Edit undoable={false} {...props}>
        <SimpleForm toolbar={<EditToolbar />}>
          <TextInput source="key" disabled />
          <SettingInput source="value" />
          <TextInput source="description" fullWidth />
        </SimpleForm>
      </Edit>
    </>
  );
};

const SettingInput = (props: any) => {
  const {
    record: { type },
  } = props;

  switch (type) {
    case 'html':
      return <RichTextInput {...props} />;
    case 'number':
      return <NumberInput {...props} />;
    default:
      return <TextInput multiline {...props} />;
  }
};

export default SettingEdit;
