import React from 'react';
import { Edit, ReferenceInput, SelectInput, SimpleForm, TextInput } from 'react-admin';
import TitleBreadcrumb from '../../../components/TitleBreadcrumb';
import SaveDeleteToolbar from '../../../components/SaveDeleteToolbar';

const CarModelEdit = (props: any) => {
  const { id } = props;
  const breadcrumb = [
    { link: '/car-models', title: 'Car Models' },
    { link: `/car-models/${id}`, title: id },
  ];

  return (
    <>
      <TitleBreadcrumb paths={breadcrumb} />
      <Edit undoable={false} {...props} title="Edit Car Model">
        <SimpleForm
          toolbar={
            <SaveDeleteToolbar
              confirmDeleteContent={`The deletion is PERMANENT. The related Car Models WILL ALSO BE DELETED. Are you sure to delete this?`}
              savePermission={`car-models.update`}
              deletePermission={`car-models.delete`}
            />
          }
        >
          <TextInput source="id" disabled />
          <TextInput source="name" fullWidth />
          <ReferenceInput
            source="car_maker_id"
            reference="car-makers"
            fullWidth
            order="name"
            sort={{ field: 'name', order: 'ASC' }}
            perPage={1000}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
        </SimpleForm>
      </Edit>
    </>
  );
};

export default CarModelEdit;
