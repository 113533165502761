import React from 'react';
import { Helmet } from 'react-helmet';
import { Datagrid, Filter, List, TextField, TextInput } from 'react-admin';
import IdInput from '../../components/IdInput';
import StatusField from '../../components/StatusField';
import UserStatusInput from '../../components/UserStatusInput';

const PostFilter = (props: any) => (
  <Filter {...props}>
    <IdInput label="ID" source="id" />
    <TextInput label="Email" source="user.email" />
    <TextInput label="Name and Mobile" source="search" />
    <UserStatusInput label="Status" source="user.status" />
  </Filter>
);

const CustomerList = (props: any) => (
  <List exporter={false} filters={<PostFilter />} bulkActionButtons={false} {...props}>
    <Datagrid rowClick="edit">
      <Helmet>
        <title>Customers</title>
      </Helmet>
      <TextField label="User ID" source="id" />
      <TextField label="Email" source="user.email" />
      <TextField label="Name" source="full_name" />
      <TextField label="Mobile" source="phone" />
      <StatusField source="user.status" />
    </Datagrid>
  </List>
);

export default CustomerList;
