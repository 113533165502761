import WorkshopList from './WorkshopList';
import WorkshopIcon from '@material-ui/icons/Build';
import WorkshopCreate from './WorkshopCreate';
import WorkshopEdit from './WorkshopEdit';

export default {
  list: WorkshopList,
  edit: WorkshopEdit,
  icon: WorkshopIcon,
  create: WorkshopCreate,
};
