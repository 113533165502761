import CarModelList from "./CarModelList";
import CarModelCreate from "./CarModelCreate";
import CardModelEdit from "./CarModelEdit";
import CarIcon from "@material-ui/icons/DirectionsCar";

export default {
  list: CarModelList,
  create: CarModelCreate,
  edit: CardModelEdit,
  icon: CarIcon
};
