import { Box, Grid } from '@material-ui/core';
import React from 'react';
import {
  ArrayInput,
  FormWithRedirect,
  NumberInput,
  SaveButton,
  SelectInput,
  SimpleFormIterator,
  TextInput,
  Toolbar,
  useDataProvider,
  usePermissions,
  Loading,
} from 'react-admin';
import ImageInput from '../../components/ImageInput';
import UserForm from '../../components/UserForm';
import { CarMaker, Product, Workshop } from '../../types';

interface Props {
  [x: string]: any;
}
interface Options {
  id: object;
  name: string;
}

/**
 * Custom form component
 * https://github.com/marmelab/react-admin/blob/master/docs/CreateEdit.md
 * @param props
 */
const WorkshopForm = (props: Props) => {
  const [carOptions, setCarOptions] = React.useState<Options[]>([]);
  const [serviceOptions, setServiceOptions] = React.useState<Options[]>([]);
  const dataProvider = useDataProvider();
  const { permissions } = usePermissions();

  // load car options
  React.useEffect(() => {
    dataProvider
      .getList('car-makers', {
        pagination: {
          perPage: 1000,
        },
      })
      .then(({ data: cars }: { data: CarMaker[] }) => cars)
      // .then((cars: CarMaker[]) => cars.map((car) => ({ id: car.id, name: car.name })))
      .then((options: Options[]) => setCarOptions(options));
  }, []);

  // load service options
  React.useEffect(() => {
    dataProvider
      .getList('products', {
        pagination: {
          perPage: 1000,
        },
      })
      .then(({ data }: { data: Product[] }) => data)
      // .then((products: Product[]) => products.map((product) => ({ id: product.id, name: product.name })))
      .then((options: Options[]) => setServiceOptions(options));
  }, []);

  if (!permissions) {
    return <Loading />;
  }

  return (
    <FormWithRedirect
      {...props}
      title=""
      validate={validate}
      render={(formProps: any) => (
        <form>
          <Grid container spacing={3}>
            <Grid item md={6}>
              <h2>Account Detail</h2>
              <UserForm />
            </Grid>
            <Grid item md={6}>
              <h2>Company Details</h2>
              <TextInput source="company_name" fullWidth />
              <TextInput source="company_gst" inputProps={{ maxLength: 10 }} fullWidth />
              <TextInput source="company_uen" inputProps={{ maxLength: 10 }} fullWidth />
            </Grid>
            <Grid item md={6}>
              <h2>Owner</h2>
              <TextInput source="full_name" fullWidth />
              <NumberInput source="owner_phone" inputProps={{ maxLength: 8 }} fullWidth />
            </Grid>

            <Grid item md={6}>
              <h2>Workshop Details</h2>
              <TextInput source="name" label="Workshop Name" fullWidth />
              <TextInput source="description" multiline rows="5" fullWidth />
              <NumberInput source="phone" label="Workshop Phone" inputProps={{ maxLength: 8 }} fullWidth />
              <ImageInput
                source="photo_profile_url"
                label="Workshop Photo"
                accept="image/jpeg, image/png"
                fullWidth
              />

              <TextInput source="opening_hours" label="Workshop Opening hours" multiline rows="2" fullWidth />
              <TextInput source="address.address_1" label="Workshop Address 1" fullWidth />
              <TextInput source="address.address_2" label="Workshop Address 2" fullWidth />
              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <TextInput
                    source="address.level"
                    label="Workshop Level"
                    inputProps={{ maxLength: 3 }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextInput
                    source="address.unit"
                    label="Workshop Number"
                    inputProps={{ maxLength: 3 }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    source="address.postal_code"
                    label="Workshop Postal Code"
                    inputProps={{ maxLength: 6 }}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={6}>
              <h2>Price Markup</h2>
              <NumberInput
                source="markup_price_percentage"
                label="Markup Percentage"
                inputProps={{ maxLength: 2 }}
                fullWidth
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item md={6}>
              <h2>Primary Products</h2>
              <ArrayInput source="primary_products" fullLength>
                <SimpleFormIterator>
                  <SelectInput source="id" choices={serviceOptions} label="Primary Product" />
                </SimpleFormIterator>
              </ArrayInput>
            </Grid>

            <Grid item md={6}>
              <h2>Secondary Products</h2>
              <ArrayInput source="secondary_products" fullLength>
                <SimpleFormIterator>
                  <SelectInput source="id" choices={serviceOptions} label="Secondary Product" />
                </SimpleFormIterator>
              </ArrayInput>
            </Grid>

            <Grid item md={6}>
              <h2>Primary Car Makes</h2>
              <ArrayInput source="primary_car_makers" fullLength>
                <SimpleFormIterator>
                  <SelectInput source="id" choices={carOptions} label="Primary Car Makes" />
                </SimpleFormIterator>
              </ArrayInput>
            </Grid>

            <Grid item md={6}>
              <h2>Secondary Car Makes</h2>
              <ArrayInput source="secondary_car_makers" fullLength>
                <SimpleFormIterator>
                  <SelectInput source="id" choices={carOptions} label="Secondary Car Makes" />
                </SimpleFormIterator>
              </ArrayInput>
            </Grid>
          </Grid>

          <Toolbar>
            <Box display="flex" justifyContent="space-between" width="100%">
              {permissions('workshops.update') && (
                <SaveButton
                  saving={formProps.saving}
                  handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                />
              )}
            </Box>
          </Toolbar>
        </form>
      )}
    />
  );
};

const validate = (values: Workshop) => {
  const errors: any = {};
  const { phone, owner_phone, markup_price_percentage } = values;
  const validationErrors = {
    ...errors,
  };
  return validationErrors;
};

export default WorkshopForm;
