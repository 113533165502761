import React from 'react';
import { List, Datagrid, TextField, ReferenceField, ImageField, Filter, TextInput } from 'react-admin';
import { ListFilter } from '../../../components/additional';
import { Helmet } from 'react-helmet';
import IdInput from '../../../components/IdInput';
import CarMakeInput from '../../../components/CarMakeInput';
import ListActions from '../../../components/ListActions';

const MyFilter = (props: any) => (
  <Filter {...props}>
    <IdInput label="Id" source="id" />
    <CarMakeInput label="Car Make" />
    <TextInput label="Car Model" source="search" />
  </Filter>
);

const CarModelList = (props: any) => (
  <List
    sort={{ field: 'name', order: 'asc' }}
    exporter={false}
    filters={<MyFilter />}
    bulkActionButtons={false}
    {...props}
    title="Car Model"
    actions={<ListActions permission={`car-models.create`} />}
  >
    <Datagrid rowClick="edit">
      <Helmet>
        <title>Car Model</title>
      </Helmet>
      <TextField source="id" />
      <ReferenceField
        allowEmpty={true}
        label="Car Make"
        source="car_maker_id"
        reference="car-makers"
        link={false}
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField label="Car Model" source="name" />
    </Datagrid>
  </List>
);

export default CarModelList;
