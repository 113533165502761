import React from 'react';
import Chip from '@material-ui/core/Chip';
import get from 'lodash/get';

const orderStatus = [
  // NEW
  { key: 'ORDER_NEW', name: 'NEW' },
  { key: 'ORDER_ASSIGNMENT_SUCCESS', name: 'ASSIGNED' },
  { key: 'ORDER_INSPECTION_STARTED', name: 'INSPECTION STARTED' },
  // ONGOING
  { key: 'ORDER_INSPECTION_CAR_WITH_SA', name: 'INSPECTION COMPLETED' },
  { key: 'ORDER_INSPECTION_CAR_WITH_WO', name: 'IN WORKSHOP' },
  { key: 'ORDER_QUOTATION_START', name: 'IN WORKSHOP' },
  { key: 'ORDER_QUOTATION_ACCEPTED', name: 'IN WORKSHOP' },
  { key: 'ORDER_WORK_STARTED', name: 'IN WORKSHOP' },
  { key: 'ORDER_WORK_SUCCESS', name: 'READY FOR SERVICE INSPECTION' },
  { key: 'ORDER_RETURN_CAR_WITH_SA', name: 'SERVICE COMPLETED' },
  // PAST
  { key: 'ORDER_RETURN_CAR_SUCCESS', name: 'JOB CLOSED' },
  { key: 'ORDER_RATED', name: 'JOB CLOSED' },
  { key: 'ORDER_CANCEL', name: 'CANCELLED' },
  { key: 'ORDER_QUOTATION_EXPIRED', name: 'JOB CLOSED' },
  { key: 'ORDER_QUOTATION_PAID', name: 'JOB CLOSED' },
  { key: 'ORDER_ASSIGNMENT_EXPIRED', name: 'JOB CLOSED' },
];
// active, banned, pending
const userStatus = [
  { key: 'active', name: 'ACTIVE' },
  { key: 'banned', name: 'BANNED' },
  { key: 'pending', name: 'PENDING' },
];

interface IStatusField {
  label?: string;
  record?: any;
  source: string;
}

const style = {
  display: 'block',
  marginBottom: '5px',
  color: '#757575',
};

const getStatus = (type: string) => {
  switch (type) {
    case 'order':
      return orderStatus;
      break;
    case 'user':
      return userStatus;
      break;
    default:
      return orderStatus;
      break;
  }
};

export const translateStatus = (status: string): string => {
  return status
    .replace('ORDER_', '') // remove ORDER_ from status, e.g. ORDER_EXPIRED to EXPIRED
    .replace('STATUS_', '')
    .replace(/_/g, ' ') // replace all _ in status, i.e. ASSIGNMENT_SUCCESS --> ASSIGNMENT SUCCESS
    .toUpperCase();
};

const StatusField = ({ label, record = {}, source }: IStatusField) => {
  const status = get(record, source);
  const translated = translateStatus(status);

  return (
    <div>
      <Chip label={translated} />
    </div>
  );
};

export default StatusField;
