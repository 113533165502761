import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles({
  image: {
    cursor: 'pointer',
    height: '200px',
    objectFit: 'cover',
    margin: '20px',
  },
  imageDialog: {
    objectFit: 'cover',
    maxHeight: '80vh',
  },
});

interface SimpleDialogProps {
  open: boolean;
  selectedValue: string;
  onClose: () => void;
}

export const ImageDialog = (props: SimpleDialogProps) => {
  const { onClose, selectedValue, open } = props;
  const classes = useStyles();

  return (
    <Dialog maxWidth="lg" onClose={onClose} open={open}>
      <img className={classes.imageDialog} src={selectedValue} alt={selectedValue} />
    </Dialog>
  );
};
