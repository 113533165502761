import PromoCodeIcon from '@material-ui/icons/LocalOffer';
import { getRequiredValidation } from '../../utils/validate';
import PromoCodeCreate from './PromoCodeCreate';
import PromoCodeEdit from './PromoCodeEdit';
import PromoCodeList from './PromoCodeList';
import { PromoCode } from '../../types';

export const validate = (values: PromoCode) => {
  const errors: any = {};
  if (values.end_date && new Date() > new Date(values.end_date)) {
    errors['end_date'] = ['End date must be after today'];
  }
  if (values.end_date && new Date(values.start_date) > new Date(values.end_date)) {
    errors['end_date'] = ['End date must be after start date'];
  }
  if (isNaN(values.usage_max_count)) {
    errors['usage_max_count'] = ["Usage max count can't be empty"];
  }

  const validationErrors = {
    ...errors,
    ...getRequiredValidation(values, [
      'code',
      'value',
      'type',
      'status',
      'start_date',
      'end_date',
    ]),
  };
  console.log(validationErrors);
  return validationErrors;
};

export default {
  list: PromoCodeList,
  create: PromoCodeCreate,
  edit: PromoCodeEdit,
  icon: PromoCodeIcon,
};
