import React from 'react';
import { Datagrid, Filter, List, ReferenceField, TextField } from 'react-admin';
import { Helmet } from 'react-helmet';
import OrderStatusSelectInput from '../../components/OrderStatusSelectInput';
import StatusField from './../../components/StatusField';
import IdInput from '../../components/IdInput';
import CustomerInput from '../../components/CustomerInput';
import WorkshopInput from '../../components/WorkshopInput';
import AdvisorInput from '../../components/AdvisorInput';
import CustomDateField from '../../components/CustomDateField';

const OrderFilter = (props: any) => (
  <Filter {...props}>
    <IdInput source="id" />
    <OrderStatusSelectInput source="status" />
    <CustomerInput source="customer_id" />
    <WorkshopInput source="workshop_id" />
    <AdvisorInput source="advisor_id" label="Ambassador" />
  </Filter>
);

const OrderList = (props: any) => (
  <List
    sort={{ field: 'created_at', order: 'DESC' }}
    exporter={false}
    filters={<OrderFilter />}
    bulkActionButtons={false}
    {...props}
  >
    <Datagrid rowClick="show">
      <Helmet>
        <title>Orders</title>
      </Helmet>
      <TextField source="id" />
      <CustomDateField source="created_at" />
      <ReferenceField source="customer_id" reference="customers" link={false}>
        <TextField source="full_name" />
      </ReferenceField>
      <ReferenceField source="advisor_id" reference="advisors" label="Ambassador" link={false}>
        <TextField source="full_name" />
      </ReferenceField>
      <ReferenceField source="workshop_id" reference="workshops" link={false}>
        <TextField source="name" />
      </ReferenceField>
      <CustomDateField source="inspection_date" label="Pickup Date" />
      <CustomDateField source="collection_date" label="Delivery Date" />
      <StatusField source="status" />
    </Datagrid>
  </List>
);

export default OrderList;
