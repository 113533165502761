import AdminList from './AdminList';
import AdminCreate from './AdminCreate';
import AdminIcon from '@material-ui/icons/HowToReg';
import AdminEdit from './AdminEdit';

export default {
  list: AdminList,
  edit: AdminEdit,
  create: AdminCreate,
  icon: AdminIcon,
};
