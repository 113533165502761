import React from 'react';
import { TextInput, SelectInput } from 'react-admin';
import Grid from '@material-ui/core/Grid';

interface Props {}

const UserForm = (props: Props) => {
  const choices = [
    { id: 'active', name: 'Active' },
    { id: 'banned', name: 'Suspend' },
    { id: 'pending', name: 'Email Pending Verification' },
  ];

  return (
    <>
      <TextInput
        source="user.email"
        type="email"
        label="Email for Login"
        inputProps={{ disabled: false }}
        {...props}
        fullWidth
        style={{ marginRight: '20px' }}
      />
      <SelectInput source="user.status" label="Status" choices={choices} {...props} fullWidth />
    </>
  );
};

export default UserForm;
