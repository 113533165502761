import React from 'react';
import { Address } from '../../types';
import get from 'lodash/get';
import { Labeled } from 'react-admin';

interface Props {
  record?: object;
  source: string;
  label?: string;
}

const AddressField: React.FC<Props> = ({ record, source, label = 'Address' }) => {
  const address: Address = get(record, source);

  if (!address) {
    return <>-</>;
  }
  if (!address.address_1) {
    return <>-</>;
  }

  return (
    <pre style={{ margin: 0 }}>
      {address?.address_1}
      <br />
      {address?.address_2}
      <br />#{address.level}-{address.unit}
      <br />
      {address.postal_code}
      <br />
      {address.notes}
    </pre>
  );
};

export default AddressField;
