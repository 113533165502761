import React from 'react';
import { Helmet } from 'react-helmet';
import { Filter, TextInput, List, Datagrid, TextField, EmailField, ReferenceField } from 'react-admin';
import IdInput from '../../components/IdInput';
import StatusField from '../../components/StatusField';
import UserStatusInput from '../../components/UserStatusInput';
import TotalCurJobField from './TotalCurJobField';
import ListActions from '../../components/ListActions';

const SAFilter = (props: any) => (
  <Filter {...props}>
    <IdInput label="ID" source="id" />
    <TextInput label="Name and Phone" source="search" />
    <UserStatusInput label="Status" source="user.status" />
    <TextInput label="Email" source="user.email" />
  </Filter>
);

const ServiceAdvisorList = (props: any) => (
  <>
    <Helmet>
      <title>Ambassador</title>
    </Helmet>
    <List
      sort={{ field: 'id', order: 'DESC' }}
      exporter={false}
      filters={<SAFilter />}
      bulkActionButtons={false}
      {...props}
      actions={<ListActions permission="advisors.create" />}
    >
      <Datagrid rowClick="edit">
        <TextField source="id" label="Ambassador ID" />
        <EmailField source="user.email" label="Login Email" />
        <TextField source="full_name" />
        <TextField label="Mobile no." source="phone" />
        <TotalCurJobField source="id" sortable={false} />
        <StatusField source="user.status" label="Status" />
      </Datagrid>
    </List>
  </>
);

export default ServiceAdvisorList;
