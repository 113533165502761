import { Theme, useMediaQuery } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import ReportIcon from '@material-ui/icons/Assignment';
import BuildIcon from '@material-ui/icons/Build';
import CarIcon from '@material-ui/icons/DirectionsCar';
import MasterDataIcon from '@material-ui/icons/Dns';
import EmojiTransportationOutlinedIcon from '@material-ui/icons/EmojiTransportationOutlined';
import MailIcon from '@material-ui/icons/Mail';
import FAQIcon from '@material-ui/icons/ModeComment';
import SettingsIcon from '@material-ui/icons/Settings';
import React, { FC, useState } from 'react';
import { DashboardMenuItem, Loading, MenuItemLink, usePermissions } from 'react-admin';
import { useSelector } from 'react-redux';
import admins from '../../pages/admins';
import customers from '../../pages/customers';
import orders from '../../pages/orders';
import promoCodes from '../../pages/promoCodes';
import publicHolidays from '../../pages/publicHoliday';
import serviceAdvisors from '../../pages/serviceAdvisors';
import workshops from '../../pages/workshops';
import { AppState } from '../../types';
import { getUnreadInboxItemsCount } from '../../utils/tools';
import SubMenu from './SubMenu';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';

type SubMenuName = 'users' | 'masterData';

interface Props {
  dense: boolean;
  logout: () => void;
  onMenuClick: () => void;
}

const Menu: FC<Props> = ({ onMenuClick, dense, logout }) => {
  const [inboxCount, setInboxCount] = React.useState(0);
  const [subMenus, setSubmenus] = useState({
    users: false,
    masterData: false,
  });
  const { permissions } = usePermissions();

  // const translate = useTranslate();
  const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  const open = useSelector((state: AppState) => state.admin.ui.sidebarOpen);
  useSelector((state: AppState) => state.theme); // force rerender on theme change

  // every 30 seconds, get inbox count.
  React.useEffect(() => {
    const timer = setInterval(() => {
      getUnreadInboxItemsCount().then((data: any) => setInboxCount(data.total));
    }, 30000);
    return () => clearInterval(timer);
  }, []);

  const handleToggleSubMenu = (toggledSubMenu: SubMenuName) => {
    setSubmenus({ ...subMenus, [toggledSubMenu]: !subMenus[toggledSubMenu] });
  };

  if (!permissions) {
    return <Loading />;
  }

  return (
    <div>
      {permissions(`dashboard.read`) && <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} />}

      {permissions('orders.read') && (
        <MenuItemLink
          to={`/orders`}
          primaryText={'Orders'}
          leftIcon={<orders.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      )}

      {permissions('inboxes.read') && (
        <MenuItemLink
          to={`/inboxes`}
          primaryText="Inbox"
          leftIcon={
            <Badge badgeContent={inboxCount} color="primary" variant="dot">
              <MailIcon />
            </Badge>
          }
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      )}

      {permissions('users.read') && (
        <SubMenu
          handleToggle={() => handleToggleSubMenu('users')}
          isOpen={subMenus.users}
          sidebarIsOpen={open}
          name="Users"
          icon={<customers.icon />}
          dense={dense}
        >
          <MenuItemLink
            to={`/customers`}
            primaryText={'Customers'}
            leftIcon={<customers.icon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />

          <MenuItemLink
            to={`/workshops`}
            primaryText={'Workshops'}
            leftIcon={<workshops.icon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />

          <MenuItemLink
            to={`/advisors`}
            primaryText={'Ambassadors'}
            leftIcon={<serviceAdvisors.icon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />

          <MenuItemLink
            to={`/admins`}
            primaryText={'Admins'}
            leftIcon={<admins.icon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
        </SubMenu>
      )}

      {permissions('customerCars.read') && (
        <MenuItemLink
          to={`/customer-cars`}
          primaryText={'Cars'}
          leftIcon={<DirectionsCarIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      )}

      {permissions('reports.read') && (
        <MenuItemLink
          to={`/reports`}
          primaryText={'Reports'}
          leftIcon={<ReportIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      )}

      {permissions('promo-codes.read') && (
        <MenuItemLink
          to={`/promo-codes`}
          primaryText={'Promos'}
          leftIcon={<promoCodes.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      )}

      {permissions('masterData.read') && (
        <SubMenu
          handleToggle={() => handleToggleSubMenu('masterData')}
          isOpen={subMenus.masterData}
          sidebarIsOpen={open}
          name="Master Data"
          icon={<MasterDataIcon />}
          dense={dense}
        >
          {permissions('products.read') && (
            <MenuItemLink
              to={`/products`}
              primaryText={'Products/Services'}
              leftIcon={<BuildIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}

          {permissions('car-makers.read') && (
            <MenuItemLink
              to={`/car-makers`}
              primaryText={'Car Make List'}
              leftIcon={<EmojiTransportationOutlinedIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}

          {permissions('car-models.read') && (
            <MenuItemLink
              to={`/car-models`}
              primaryText={'Car Models'}
              leftIcon={<CarIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}

          {permissions('settings.read') && (
            <MenuItemLink
              to={`/settings`}
              primaryText={'Settings'}
              leftIcon={<SettingsIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}

          {permissions('faqs.read') && (
            <MenuItemLink
              to={`/faqs`}
              primaryText={'FAQs'}
              leftIcon={<FAQIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}

          {permissions('public-holidays.read') && (
            <MenuItemLink
              to={`/public-holidays`}
              primaryText={'PH Management'}
              leftIcon={<publicHolidays.icon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}
        </SubMenu>
      )}
      {isXSmall && logout}
    </div>
  );
};

export default Menu;
