import FaqList from './FaqList';
import FaqCreate from './FaqCreate';
import FaqEdit from './FaqEdit';
import FAQIcon from '@material-ui/icons/ModeComment';

export default{
    list: FaqList,
    edit: FaqEdit,
    create: FaqCreate,
    icon: FAQIcon,
}