import { parseJwt } from './utils/jwt';
import { roles } from './utils/const';
import { get } from 'lodash';

export default {
  login: ({ username, password }: any) => {
    const request = new Request(`${process.env.REACT_APP_API_URL}/auth/login`, {
      method: 'POST',
      body: JSON.stringify({ email: username, password: password }),
      headers: new Headers({ 'Content-Type': 'application/json', }),
    });

    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ jwt }) => {
        // store  jwt to localstorage
        // then read it's payload
        const payload = parseJwt(jwt);
        localStorage.setItem('token', jwt);
        return payload;
      })
      .then(({ sub, roles }) => {
        // set the properties of the app,
        // based on the payload.
        localStorage.setItem('me', sub);
        localStorage.setItem('roles', JSON.stringify(roles));
        // based on the roles, store the permissions of the user
        localStorage.setItem('permissions', JSON.stringify(getPermissions(roles)));
        return Promise.resolve();
      });
  },
  logout: (params: any) => {
    localStorage.removeItem('token');
    localStorage.removeItem('me');
    localStorage.removeItem('roles');
    localStorage.removeItem('permissions');
    return Promise.resolve();
  },
  checkAuth: () => (localStorage.getItem('token') ? Promise.resolve() : Promise.reject()),
  checkError: (error: any) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem('token');
      return Promise.reject();
    }
    return Promise.resolve();
  },

  getPermissions: (params: any) => {
    const permissions = localStorage.getItem('permissions');
    if (!permissions) {
      return Promise.reject();
    }

    return Promise.resolve((search: string) => can(search, JSON.parse(permissions)));
  },
};

const getPermissions = (roles: string[]) => {
  const permissionList = {
    super_admin: ['*'],
    customer_admin: [
      'orders.read',
      'orders.assign',
      'quotation.create',
      'quotation.revise',
      'orders.update-collection-date',
      'orders.finish',
      'orders.read.cancellation_notes',
      'orders.update-inspection-date',
      'orders.cancel',
      'users.read',
      'customers.read',
      'workshops.read',
      'reports.read',
      'reports.read.orders',
      'quotations.read',
      'quotations.read.customer_price',
      'invoices.read',
      'promo-codes.read',
      'masterData.read',
      'products.read',
      'car-makers.create',
      'car-makers.read',
      'car-models.create',
      'car-models.read',
      'settings.read',
      'faqs.read',
      'public-holidays.read',
      'dashboard.read',
      'dashboard.read.customer_acquisition',
      'dashboard.read.orders',
      'workshops.create',
    ],
    finance_admin: [
      'reports.read',
      'reports.read.transactions',
      'reports.download.transactions',
      'promo-codes.read',
      'dashboard.read.orders',
    ],
    workshop: [
      'orders.read',
      'inboxes.read',
      'quotations.read',
      `dashboard_read`,
      'dashboard.read.orders',
      'quotation.create',
      'quotation.revise',
      'orders.finish',
      'orders.update-collection-date',
    ],
  };

  const reducer = (accumulator: string[], key: string) => {
    const permissions = get(permissionList, key, []);
    return accumulator.concat(permissions);
  };

  return roles.reduce(reducer, []);
};

const can = (search: string, permissions: string[]) => {
  if (permissions.includes('*')) {
    return true;
  }

  return permissions.includes(search);
};
