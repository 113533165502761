import { has, get } from 'lodash';
import { stringify } from 'query-string';
import { DeleteResponse } from './types';
import { apiUrl } from './utils/const';
import { getUser, httpClient } from './utils/tools';

interface Query {
  page?: number;
  limit?: number;
  sort?: string;
  order?: string;
  filter?: any;
  search?: string;
}

export const composeError = (errorBody: any) => {
  const errors: string[] = Object.values(get(errorBody, 'errors', {}));
  const message = get(errorBody, 'message', []);
  let msg = `${message}. \n`;
  errors.map((val: string) => (msg = msg + val + '\n'));
  return msg;
};

export default {
  getList: (resource: any, params: any) => {
    let query: Query = {};

    if (has(params, 'pagination')) {
      const { page, perPage } = params.pagination;
      query['page'] = page;
      query['limit'] = perPage;
    }

    if (has(params, 'sort')) {
      const { field, order } = params.sort;
      query['sort'] = field;
      query['order'] = order;
    }

    if (has(params, 'filter')) {
      query['filter'] = JSON.stringify(params.filter);
      query['search'] = params.filter.search ?? '';
    }

    let url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url, getUser()).then(({ json }: any) => ({
      data: json.data,
      total: json.total,
    }));
  },

  getOne: (resource: any, params: any) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, getUser()).then(({ json }: any) => ({
      data: json,
    })),

  getMany: (resource: any, params: any) => {
    const query = {
      filter: JSON.stringify({ id: params.ids.toString() }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    return httpClient(url, getUser()).then(({ json }: any) => ({ data: json.data }));
  },

  getManyReference: (resource: any, params: any) => {
    let query: Query = {};

    if (params.pagination) {
      const { page, perPage } = params.pagination;
      query['page'] = page;
      query['limit'] = perPage;
    }

    if (params.sort) {
      const { field, order } = params.sort;
      query['sort'] = field;
      query['order'] = order;
    }

    if (params.filter) {
      query['filter'] = JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      });
    }

    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url, getUser()).then(({ headers, json }: any) => ({
      data: json.data,
      total: json.total,
    }));
  },

  update: (resource: any, params: any) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
      ...getUser(),
    })
      .then(({ json }: any) => ({ data: json }))
      .catch((error: any) => {
        const body = get(error, 'body', {});
        const msg = composeError(body);
        return Promise.reject({ message: msg });
      }),

  updateMany: (resource: any, params: any) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
      ...getUser(),
    }).then(({ json }: any) => ({ data: json }));
  },

  create: (resource: any, params: any) =>
    httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(params.data),
      ...getUser(),
    })
      .then(({ json }: any) => ({
        data: { ...params.data, id: json.id },
      }))
      .catch((error: any) => {
        const body = get(error, 'body', {});
        const msg = composeError(body);
        return Promise.reject({ message: msg });
      }),

  delete: (resource: any, params: any) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'DELETE',
      ...getUser(),
    }).then(({ json }: any) => ({ data: json })),

  deleteMany: (resource: any, params: any) => {
    const ids = params.ids;
    const deleteResource = (id: number) =>
      httpClient(`${apiUrl}/${resource}/${id}`, {
        method: 'DELETE',
        ...getUser(),
      })
        .then(({ body }: any) => JSON.parse(body))
        .then(({ message }: DeleteResponse) => (message === 'Success' ? id : null));

    return Promise.all(ids.map(deleteResource)).then((ids: any) => {
      return {
        data: ids,
      };
    });
  },
};
