import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import get from 'lodash/get';
import React from 'react';
import { Order } from '../../types';

interface Props {
  record: Order;
  label?: string;
}

const InvoiceShow = ({ record: order, ...props }: Props) => {
  const { invoice } = order;

  if (!invoice) {
    return <div style={{ padding: '20px 0' }}>Invoice has not been created yet.</div>;
  }

  // if pdf url is available, return an iframe
  const pdfUrl: string = get(invoice, 'pdf.url');
  if (pdfUrl) {
    return <iframe src={pdfUrl} style={{ width: '100%', height: '800px' }} />;
  }

  // otherwise, render the Invoice DOM
  return (
    <div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>No</TableCell>
              <TableCell>Product Description</TableCell>
              <TableCell align="right">Total ($)</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {invoice?.items.map((item, counter) => {
              return (
                <TableRow key={item.id}>
                  <TableCell>{counter + 1}</TableCell>
                  <TableCell>{item.description}</TableCell>
                  <TableCell align="right">{item.total}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>

          <TableFooter>
            <TableRow>
              <TableCell>&nbsp;</TableCell>
              <TableCell>Subtotal</TableCell>
              <TableCell align="right">{invoice?.sub_total}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>&nbsp;</TableCell>
              <TableCell>Discount</TableCell>
              <TableCell align="right">({invoice?.discount})</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>&nbsp;</TableCell>
              <TableCell>Tax</TableCell>
              <TableCell align="right">{invoice?.tax}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>&nbsp;</TableCell>
              <TableCell>Grand Total</TableCell>
              <TableCell align="right">{invoice?.total}</TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
};

export default InvoiceShow;
