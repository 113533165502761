import React from 'react';
import {
  Datagrid,
  Filter,
  ImageField,
  List,
  NumberField,
  SelectInput,
  TextField,
  TextInput,
} from 'react-admin';
import { Helmet } from 'react-helmet';
import IdInput from '../../../components/IdInput';
import StatusField from '../../../components/StatusField';
import ListActions from '../../../components/ListActions';

const MyFilter = (props: any) => (
  <Filter {...props}>
    <IdInput label="Id" source="id" />
    <TextInput label="Code/Name" source="search" />
    {/* <SelectInput
      label="Status"
      choices={[
        { id: 'active', name: 'Active' },
        { id: 'inactive', name: 'Inactive' },
      ]}
      source="status"
    /> */}
  </Filter>
);

const ServiceList = (props: any) => (
  <List
    sort={{ field: 'display_order', order: 'ASC' }}
    exporter={false}
    filters={<MyFilter />}
    filter={{ is_deleted: true }}
    bulkActionButtons={false}
    {...props}
    title="Service"
    actions={<ListActions permission={`products.create`} />}
  >
    <Datagrid rowClick="edit">
      <Helmet>
        <title>Products / Services</title>
      </Helmet>
      <ImageField source="photo_url" />
      <TextField source="id" />
      <TextField source="code" />
      <TextField source="description" />
      <NumberField source="price" />
      <NumberField source="display_order" />
      <TextField source="created_at" />
      <TextField source="deleted_at" />
    </Datagrid>
  </List>
);

export default ServiceList;
