import { Box, Button, Card, CardActions, CardContent, TextField, Typography } from '@material-ui/core';
import React from 'react';
import { Loading, useDataProvider, useNotify, useRedirect } from 'react-admin';
import { Order } from '../../types';
import { OrderStatus } from '../../utils/const';
import { canEstimateCollectionDate } from '../../utils/order';
import { useHistory } from 'react-router-dom';
import { convertJsToSql, convertSqlToJs } from '../../utils/date';
import { get } from 'lodash';

interface Props {
  order?: Order;
  onSubmit: (date: string) => Promise<any>;
  source: string;
  text: string;
  onSuccessNotification?: string;
  onErrorNotification?: string;
  label?: string;
}
const UpdateOrderDate = (props: Props) => {
  const [loading, setLoading] = React.useState(true);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const redirect = useRedirect();
  const [date, setDate] = React.useState<string>('');
  const history = useHistory();
  const { order, onSubmit, source, text } = props;
  const { onSuccessNotification = 'Success: estimated collection date has been set.' } = props;
  const { onErrorNotification = 'Error: cannot update the estimated collection date.' } = props;
  const { label = source } = props;

  React.useEffect(() => {
    if (order) {
      setLoading(false);
      console.log(order);
      setDate(convertSqlToJs(get(order, source)));
    } else {
      setLoading(true);
    }
  }, [order]);

  const handleSubmit = (props: any) => {
    setLoading(true);
    const sqlDate = convertJsToSql(date);
    onSubmit(sqlDate)
      .then((data: any) => {
        setLoading(false);
        notify(onSuccessNotification, 'info');
        redirect(`/orders/${order?.id}/show`);
      })
      .catch((error: any) => {
        setLoading(false);
        notify(onErrorNotification, 'warning');
      });
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Card>
      <CardContent>
        <Typography gutterBottom>{text}</Typography>
        <Box>
          <TextField
            label={label}
            type="datetime-local"
            value={date}
            style={{ width: '300px' }}
            onChange={(event) => {
              setDate(event.target.value);
            }}
          />
        </Box>
      </CardContent>
      <CardActions>
        <Button variant="text" onClick={() => history.goBack()}>
          Back
        </Button>
        <Button color="primary" variant="contained" onClick={handleSubmit} disabled={loading}>
          Save
        </Button>
      </CardActions>
    </Card>
  );
};

export default UpdateOrderDate;
