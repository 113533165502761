import { Box, Button, Card, CardActions, CardContent, TextField, Typography } from '@material-ui/core';
import React from 'react';
import { Loading, useDataProvider, useNotify, useRedirect } from 'react-admin';
import { Order } from '../../types';
import { OrderStatus } from '../../utils/const';
import { canEstimateCollectionDate } from '../../utils/order';
import { useHistory } from 'react-router-dom';
import { convertJsToSql, convertSqlToJs } from '../../utils/date';
import { get } from 'lodash';
import UpdateOrderDate from './UpdateOrderDate';

interface Props {
  id: number;
}
const UpdateCollectionDate = (props: Props) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [order, setOrder] = React.useState<Order | undefined>();
  const { id } = props;

  React.useEffect(() => {
    dataProvider
      .getOne('orders', { id })
      .then(({ data }: { data: Order }) => {
        setOrder(data);
      })
      .catch((error: any) => {
        notify('Error: order cannot be fetched.', 'warning');
      });
  }, []);

  const handleSubmit = (date: string) =>
    dataProvider.update('orders', {
      id,
      data: {
        status: OrderStatus.ORDER_WORK_STARTED,
        collection_date: date,
      },
    });

  return (
    <UpdateOrderDate
      order={order}
      source="collection_date"
      onSubmit={handleSubmit}
      text="Enter the estimated date that this order will be finished and the car can be collected."
    />
  );
};

export default UpdateCollectionDate;
