import { fetchUtils } from 'react-admin';
import { apiUrl } from './const';
import { stringify } from 'query-string';
import { parseJwt } from './jwt';
import numeral from 'numeral';

export const getUser = () => {
  return {
    user: {
      authenticated: true,
      token: `Bearer ${localStorage.getItem('token')}`,
    },
  };
};

export const httpClient = fetchUtils.fetchJson;

interface Get {
  json: any;
  status: number;
}

export const getUnreadInboxItemsCount = (): Promise<Get> => {
  let query: any = {};
  query['filter'] = JSON.stringify({
    is_unread: true,
    read_at: null,
  });
  const url = `${apiUrl}/inboxes?${stringify(query)}`;
  return httpClient(url, getUser()).then(({ json, status }: any) => {
    return { json, status };
  });
};

export const parsedToken = () => {
  const token = localStorage.getItem('token');
  return token ? parseJwt(token) : null;
};

export const getReport = (type: string, from: string, to: string): Promise<Get> => {
  let query: any = {};
  query['filter'] = JSON.stringify({
    fromDate: from,
    toDate: to,
    type,
  });
  const url = `${apiUrl}/reports?${stringify(query)}`;
  return httpClient(url, getUser())
    .then(({ json, status }: any) => {
      return { json, status };
    })
    .catch((error: any) => console.log(error));
};

export const money = (price: number | string) => numeral(price).format('0,0.00');
