import React from 'react';
import { SelectInput } from 'react-admin';

interface Props {
  [x: string]: any;
}

const Input = (props: Props) => {
  const choices = [
    { id: 'active', name: 'Active' },
    { id: 'banned', name: 'Banned' },
    { id: 'pending', name: 'Pending' },
  ];

  return <SelectInput source="user.status" label="Status" choices={choices} {...props} fullWidth />;
};

export default Input;
