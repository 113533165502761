import { createMuiTheme } from '@material-ui/core/styles';
import React from 'react';
import { Admin, Resource } from 'react-admin';
import ReactDOM from 'react-dom';
import authProvider from './authProvider';
import { Layout } from './components/layout';
import dataProvider from './dataProvider';
import './index.scss';
import admins from './pages/admins';
import LoginPage from './pages/auth/LoginPage';
import customerCars from './pages/customerCars';
import customers from './pages/customers';
import Dashboard from './pages/dashboard/Dashboard';
import inboxes from './pages/inboxes';
import carMakes from './pages/masterData/carMakes';
import carModels from './pages/masterData/carModels';
import faq from './pages/masterData/faq';
import services from './pages/masterData/services';
import Orders from './pages/orders';
import promoCodes from './pages/promoCodes';
import serviceAdvisors from './pages/serviceAdvisors';
import settings from './pages/settings';
import workshops from './pages/workshops';
import customRoutes from './routes';
import publicHoliday from './pages/publicHoliday';
import * as serviceWorker from './serviceWorker';
import customTheme from './theme.json';
import createHistory from 'history/createBrowserHistory';
import ReactGA from 'react-ga';

const theme = createMuiTheme(customTheme);

// to add Google Analytics
// https://stackoverflow.com/questions/56315758/google-analytics-on-react-admin
const history = createHistory();
history.listen((location) => {
  ReactGA.pageview(location.pathname);
});
ReactGA.initialize(`${process.env.REACT_APP_GA}`);
ReactGA.pageview(window.location.pathname);

const App = () => {
  return (
    <Admin
      title="Auto App"
      customRoutes={customRoutes}
      layout={Layout}
      dashboard={Dashboard}
      dataProvider={dataProvider}
      authProvider={authProvider}
      loginPage={LoginPage}
      theme={theme}
      history={history}
    >
      {(permissions: any) => [
        <Resource options={{ label: 'Customer' }} name="customers" {...customers} />,
        <Resource options={{ label: 'Workshop' }} name="workshops" {...workshops} />,
        <Resource options={{ label: 'Ambassadors' }} name="advisors" {...serviceAdvisors} />,
        <Resource options={{ label: 'Promo Code' }} name="promo-codes" {...promoCodes} />,
        <Resource options={{ label: 'Admin' }} name="admins" {...admins} />,
        <Resource name="products" {...services} />,
        <Resource name="faqs" {...faq} />,
        <Resource name="car-makers" {...carMakes} />,
        <Resource name="car-models" {...carModels} />,
        <Resource {...customerCars} />,
        <Resource {...Orders} />,
        <Resource name="public-holidays" {...publicHoliday} />,
        <Resource name="settings" {...settings} />,
        <Resource {...inboxes} />,
        <Resource name="invoices" />,
        <Resource name="accounting-journals" />,
        <Resource name="accounting-journal-transactions" />,
        <Resource name="reports" />,
      ]}
    </Admin>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
