import React from 'react';
import Title from './Title';
import Breadcrumb from './Breadcrumb';

interface Props {
  paths: {
    link: string;
    title: string;
  }[];
  title?: string;
}

const TitleBreadcrumb: React.FC<Props> = ({ paths, title }) => {
  const createTitleFromPaths = (paths: Props['paths']): string => {
    return paths.map((path) => path.title).join(' > ');
  };

  return (
    <>
      <Title value={title ?? createTitleFromPaths(paths)} />
      <Breadcrumb paths={paths} />
    </>
  );
};

export default TitleBreadcrumb;
