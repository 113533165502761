import { Box, Card, CardContent, Grid, Typography } from '@material-ui/core';
import React from 'react';
import {
  DateInput,
  FormWithRedirect,
  Loading,
  RadioButtonGroupInput,
  SaveButton,
  TextInput,
  Toolbar,
  usePermissions,
} from 'react-admin';
import UserForm from '../../components/UserForm';

const CustomerForm = (props: any) => {
  const { permissions } = usePermissions();

  if (!permissions) {
    return <Loading />;
  }

  return (
    <FormWithRedirect
      {...props}
      render={(formProps: any) => (
        <Card>
          <form>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item md={6}>
                  <h2>Account Detail</h2>
                  <UserForm />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Profile details
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextInput fullWidth source="salutation" />
                  <TextInput fullWidth source="full_name" />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    fullWidth
                    label="Mobile number"
                    source="phone"
                    type="text"
                    inputProps={{
                      maxLength: 10,
                    }}
                  />
                  <DateInput fullWidth label="Birthday" source="date_of_birth" />
                  <RadioButtonGroupInput
                    source="gender"
                    choices={[
                      { id: 'male', name: 'Male' },
                      { id: 'female', name: 'Female' },
                    ]}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Billing Address
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextInput fullWidth label="Address line 1" source="billing_address.address_1" />
                  <TextInput fullWidth label="Address line 2" source="billing_address.address_2" />
                  <TextInput
                    fullWidth
                    label="Postal Code"
                    source="billing_address.postal_code"
                    inputProps={{ maxLength: 5 }}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Toolbar>
              <Box display="flex" justifyContent="space-between" width="100%">
                {permissions('customers.update') && (
                  <SaveButton
                    saving={formProps.saving}
                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  />
                )}
              </Box>
            </Toolbar>
          </form>
        </Card>
      )}
    />
  );
};

export default CustomerForm;
