import React from 'react';
import { List, Datagrid, TextField } from 'react-admin';
import { Helmet } from 'react-helmet';

const SettingList = (props: any) => (
  <List exporter={false} bulkActionButtons={false} {...props} title="FAQ">
    <Datagrid rowClick="edit">
      <Helmet>
        <title>Setting</title>
      </Helmet>
      <TextField source="key" />
      <TextField source="description" />
    </Datagrid>
  </List>
);

export default SettingList;
