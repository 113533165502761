import React from 'react';
import { Edit, SimpleForm, TextInput } from 'react-admin';
import ImageInput from '../../../components/ImageInput';
import SaveDeleteToolbar from '../../../components/SaveDeleteToolbar';
import TitleBreadcrumb from '../../../components/TitleBreadcrumb';

const CarMakeEdit = (props: any) => {
  const { id } = props;
  const breadcrumb = [
    { link: '/car-makers', title: 'Car Makers' },
    { link: `/car-makers/${id}`, title: id },
  ];

  return (
    <>
      <TitleBreadcrumb paths={breadcrumb} />
      <Edit undoable={false} {...props} title="Edit Car Maker">
        <SimpleForm
          toolbar={
            <SaveDeleteToolbar
              confirmDeleteContent={`The deletion is PERMANENT. The related Car Models WILL ALSO BE DELETED. Are you sure to delete this?`}
              savePermission={`car-makers.update`}
              deletePermission={`car-makers.delete`}
            />
          }
        >
          <TextInput source="id" disabled />
          <TextInput source="name" fullWidth />
        </SimpleForm>
      </Edit>
    </>
  );
};

export default CarMakeEdit;
