import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { format, sub } from 'date-fns';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import CurrentJobs from './CurrentJobs';
import './dashboard.scss';
import LineDiagram from './LineDiagram';
import { Loading } from 'react-admin';

interface ICurJob {
  title: string;
  amount: number | string;
  black?: boolean;
  isLoading?: boolean;
}

const CurJob = (props: ICurJob) => (
  <div className="curjob-item">
    {!props.isLoading && <div className={props.black ? 'num-black' : 'num'}>{props.amount}</div>}
    {props.isLoading && <CircularProgress size={30} className="curjob-loading" />}
    <div className="title">{props.title.replace(/_/g, ' ').toUpperCase()}</div>
  </div>
);

export default ({ permissions }: any) => {
  const [end, setEnd] = React.useState(format(new Date(), 'yyyy-MM-dd'));
  const [start, setStart] = React.useState(format(sub(new Date(), { days: 7 }), 'yyyy-MM-dd'));
  const [startDate, setStartDate] = useState(start);
  const [endDate, setEndDate] = useState(end);
  const [open, setOpen] = useState(false);

  const applyFilter = () => {
    setStartDate(start);
    setEndDate(end);
    setOpen(false);
  };

  const handleClose = () => {
    setStartDate(startDate);
    setEndDate(endDate);
    setOpen(false);
  };

  if (!permissions) {
    return <Loading />;
  }

  return (
    <div className="Dashboard">
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <h1>Welcome to AutoApp</h1>
      <div className="filter">
        <Button color="primary" onClick={() => setOpen(true)}>
          Set Date Range
        </Button>
        <Dialog onClose={handleClose} maxWidth="sm" aria-labelledby="customized-dialog-title" open={open}>
          <DialogTitle id="customized-dialog-title">Filter By Date</DialogTitle>
          <DialogContent dividers>
            <TextField
              fullWidth={true}
              variant="filled"
              id="date"
              onChange={(e) => setStart(e.target.value)}
              value={start}
              label="From"
              type="date"
            />
            <TextField
              fullWidth={true}
              variant="filled"
              style={{ marginTop: '20px' }}
              id="date"
              onChange={(e) => setEnd(e.target.value)}
              value={end}
              label="To"
              type="date"
            />
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={applyFilter} color="primary">
              Apply filter
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      <Grid container spacing={3}>
        <Grid item xs={2}>
          <CurrentJobs permissions={permissions} />
        </Grid>
        <Grid item xs={10}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <LineDiagram key="sales" fromDate={startDate} toDate={endDate} type="sales" title="Sales" />
            </Grid>
            {permissions('dashboard.read.customer_acquisition') && (
              <Grid item xs={6}>
                <LineDiagram
                  fromDate={startDate}
                  toDate={endDate}
                  type="customer_acquisition"
                  title="New Users"
                />
              </Grid>
            )}
            {permissions('dashboard.read.orders') && (
              <Grid item xs={6}>
                <LineDiagram
                  key="orders"
                  fromDate={startDate}
                  toDate={endDate}
                  type="orders"
                  title="New Orders"
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
