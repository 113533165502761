import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  Toolbar,
  SaveButton,
  usePermissions,
  Loading,
  SelectInput,
} from 'react-admin';
import TitleBreadcrumb from '../../components/TitleBreadcrumb';
import UserForm from '../../components/UserForm';
import Card from '@material-ui/core/Card';
import { first } from 'lodash';

const CustomToolbar = (props: any) => {
  const { permissions } = usePermissions();
  if (!permissions) {
    return <Loading />;
  }
  return <Toolbar {...props}>{permissions('admin.update') && <SaveButton />}</Toolbar>;
};
const AdminEdit = (props: any) => {
  const { id } = props;
  const breadcrumb = [
    { link: '/admins', title: 'Admins' },
    { link: `/admins/${id}`, title: id },
  ];

  return (
    <>
      <TitleBreadcrumb paths={breadcrumb} />
      <Edit {...props} undoable={false}>
        <SimpleForm toolbar={<CustomToolbar />}>
          <UserForm />
          <TextInput source="full_name" />
          <SelectInput
            source="roles"
            format={(v: any) => first(v)}
            parse={(v: string) => [v]}
            choices={[
              { id: 'super_admin', name: 'Super Admin' },
              { id: 'customer_admin', name: 'Customer Admin' },
            ]}
          />
        </SimpleForm>
      </Edit>
    </>
  );
};

export default AdminEdit;
