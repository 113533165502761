import React from 'react';
import { Datagrid, Filter, List, TextField, TextInput } from 'react-admin';
import IdInput from '../../../components/IdInput';
import TitleBreadcrumb from '../../../components/TitleBreadcrumb';
import CustomDateField from '../../../components/CustomDateField';
import ListActions from '../../../components/ListActions';

const MyFilter = (props: any) => (
  <Filter {...props}>
    <IdInput label="ID" source="id" />
    <TextInput label="Question" source="search" />
  </Filter>
);

const FaqList = (props: any) => (
  <>
    <TitleBreadcrumb paths={[]} title="FAQs" />
    <List
      exporter={false}
      bulkActionButtons={false}
      filters={<MyFilter />}
      {...props}
      title="FAQs"
      actions={<ListActions permission={`faqs.create`} />}
    >
      <Datagrid rowClick="edit">
        <TextField source="id" label="FAQ ID" />
        <TextField source="question" />
        <CustomDateField source="updated_at" />
        <TextField source="display_order" />
      </Datagrid>
    </List>
  </>
);

export default FaqList;
