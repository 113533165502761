import ICAL from "ical.js";

//this code will work only with format that the same with Singapore ics public holiday file
export const parseICS = (ICSString: string) => {
    try {
        let res: any[] = [];
        const jcal = ICAL.parse(ICSString);
        // console.log(jcal);

        jcal[2].forEach((el: any) => {
            // console.log(`dstart ${el[1][0][3]}, dend ${el[1][1][3]}, summary ${el[1][7][3]}`);
            res.push({ date: el[1][0][3], title: el[1][7][3] });
        });

        return res;
    } catch (icalerr) {
        // console.log(icalerr);
        return null;
    }
}