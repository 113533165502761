import React from 'react';
import { Datagrid, Filter, List, TextField, TextInput } from 'react-admin';
import { Helmet } from 'react-helmet';
import IdInput from '../../components/IdInput';
import StatusField from '../../components/StatusField';
import UserStatusInput from '../../components/UserStatusInput';
import ListActions from '../../components/ListActions';
import Chip from '@material-ui/core/Chip';
import { get } from 'lodash';

const AdminFilter = (props: any) => (
  <Filter {...props}>
    <IdInput label="ID" source="id" />
    <TextInput label="Email" source="user.email" />
    <TextInput label="Name" source="search" />
    <UserStatusInput label="Status" source="user.status" />
  </Filter>
);

const AdminList = (props: any) => (
  <>
    <Helmet>
      <title>Admins</title>
    </Helmet>
    <List
      exporter={false}
      filters={<AdminFilter />}
      bulkActionButtons={false}
      {...props}
      actions={<ListActions permission="admins.create" />}
    >
      <Datagrid rowClick="edit">
        <TextField label="User ID" source="user.id" />
        <TextField label="Login Email" source="user.email" />
        <TextField label="Name" source="full_name" />
        <RolesField source="roles" />
        <StatusField label="Status" source="user.status" />
      </Datagrid>
    </List>
  </>
);

const RolesField = ({ source, record }: any) => {
  const roles = get(record, source, []);

  return (
    <>
      {roles.map((role: string) => (
        <Chip label={role} key={role} />
      ))}
    </>
  );
};

export default AdminList;
