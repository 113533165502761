import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { Parser } from 'json2csv';
import { get } from 'lodash';
import React from 'react';
import { showNotification, Title, useDataProvider, usePermissions } from 'react-admin';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { apiUrl } from '../../utils/const';
import { getReport } from '../../utils/tools';
import './report.scss';

const Report = (props: any) => {
  const [reportType, setReportType] = React.useState('');
  const [fromDate, setFromDate] = React.useState(formatDate());
  const [toDate, setToDate] = React.useState(formatDate());
  const [disabled, setDisabled] = React.useState(true);
  const [data, setData] = React.useState([]);
  const { permissions } = usePermissions();
  const [reportTypes, setReportTypes] = React.useState<any[]>([]);

  React.useEffect(() => {
    if (!permissions) {
      return setReportTypes([]);
    }

    let types = [];
    if (permissions('reports.read.transactions')) {
      types.push({ id: 'transactions', name: 'Transactions' });
    }
    if (permissions('reports.read.customers')) {
      types.push({ id: 'customers', name: 'Customers' });
    }
    if (permissions('reports.read.workshops')) {
      types.push({ id: 'workshops', name: 'Workshops' });
    }
    if (permissions('reports.read.orders')) {
      types.push({ id: 'orders', name: 'Orders' });
    }
    if (permissions('reports.read.customerCars')) {
      types.push({ id: 'customer-cars', name: 'Customer Cars' });
    }

    setReportTypes(types);
  }, [permissions]);

  const handleDownload = async () => {
    //direct download the csv
    let rawData = data;
    let parser = new Parser();

    if (rawData.length <= 0) {
      props.showNotification(`There is no data from ${fromDate} to ${toDate}`, 'error');
      return;
    }

    // solution from stackoverflow.com/a/23922475/1043839
    // so, convert the CSV data to blob instead of encodeURI
    const csv = parser.parse(rawData);
    var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    var encodedUri = URL.createObjectURL(blob);

    var link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `${reportType}_${fromDate}_${toDate}.csv`);
    document.body.appendChild(link);

    link.click();
  };

  function formatDate(date?: string) {
    var d = date ? new Date(date) : new Date(),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  const handleQuery = () => {
    getReport(reportType, fromDate, toDate)
      .then(({ json }) => setData(json))
      .catch((error: any) => console.log(error));
  };

  const renderTable = (data: any) => {
    if (!data || data.length < 1) {
      return <>No Data</>;
    }

    const keys = Object.keys(data[0]);

    return (
      <div className="table">
        <h2>{reportTypes.find((type) => type.id == reportType)?.name}</h2>
        <Button onClick={handleDownload} color="primary" size="small">
          Download to CSV
        </Button>
        <table>
          <thead>
            <tr>
              {keys.map((key: string) => (
                <th key={key}>{key}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row: any, index: number) => (
              <tr key={index}>
                {keys.map((key) => (
                  <td key={key}>{get(row, key, '')}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const handleChange = (event: any) => {
    setDisabled(event.target.value === '' ? true : false);
    setReportType(event.target.value);
  };

  return (
    <Card className="Report">
      <Helmet>
        <title>Reports</title>
      </Helmet>
      <Title title="Report" />
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Report
        </Typography>
        <div className="form-report">
          <FormControl variant="filled" className="form-control">
            <InputLabel id="report-type">Report Type</InputLabel>
            <Select labelId="report-type" id="report-type" value={reportType} onChange={handleChange}>
              <MenuItem value="" key="none">
                <em>None</em>
              </MenuItem>
              {reportTypes
                .sort((a, b) => {
                  if (a.name < b.name) {
                    return -1;
                  }
                  return 1;
                })
                .map((val) => {
                  return (
                    <MenuItem value={val.id} key={val.id}>
                      {val.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
          <TextField
            className="form-control"
            variant="filled"
            id="date"
            onChange={(e) => setFromDate(e.target.value)}
            defaultValue={fromDate}
            label="From"
            type="date"
          />
          <TextField
            className="form-control"
            variant="filled"
            id="date"
            onChange={(e) => setToDate(e.target.value)}
            defaultValue={toDate}
            label="To"
            type="date"
          />
        </div>
        <div className="report-action">
          <Button onClick={handleQuery} variant="contained" disabled={disabled} color="primary">
            Search
          </Button>
        </div>

        {renderTable(data)}
      </CardContent>
    </Card>
  );
};

export default connect(null, { showNotification })(Report);
