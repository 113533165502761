import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
// import RichTextInput from 'ra-input-rich-text';
import Typography from '@material-ui/core/Typography';
import { Title } from 'react-admin';
export default (props: any) => (
    <Card>
        <Title title="Privacy Policy" />
        <CardContent>
            <Typography variant="h6" gutterBottom>
                Privacy Policy
            </Typography>
        </CardContent>
    </Card>
);