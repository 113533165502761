import React from 'react';
import { Loading } from 'react-admin';
import CarField from '../../components/CarField';
import { Customer } from '../../types';

interface Props {
  record?: Customer;
}

const CarList = ({ record }: Props) => {
  if (!record) {
    return <Loading />;
  }
  const { cars } = record;
  console.log(record);

  return (
    <div className="CarList">
      {cars.length === 0 && <div>Customer has not added any cars yet.</div>}
      {cars.map((car) => {
        const record = { car };
        return <CarField source="car" record={record} />;
      })}
    </div>
  );
};

export default CarList;
