import SAIcon from '@material-ui/icons/AssignmentInd';
import ServiceAdvisorCreate from './ServiceAdvisorCreate';
import ServiceAdvisorEdit from './ServiceAdvisorEdit';
import ServiceAdvisorList from './ServiceAdvisorList';

export default {
  list: ServiceAdvisorList,
  edit: ServiceAdvisorEdit,
  icon: SAIcon,
  create: ServiceAdvisorCreate,
};
