import Button from '@material-ui/core/Button';
import React from 'react';
import { Loading, Show, Tab, TabbedShowLayout, TopToolbar, usePermissions, useTranslate } from 'react-admin';
import TitleBreadcrumb from '../../components/TitleBreadcrumb';
import { useStyle } from '../../hooks/style';
import { OrderActions, OrderStatus } from '../../utils/const';
import {
  canCancel,
  canFinishWork,
  canReassignAdvisor,
  canReassignWorkshop,
  canEstimateCollectionDate,
  canReviseQuotation,
  canUpdateInspectionDate,
} from '../../utils/order';
import Detail from './Detail';
import InspectionShow from './InspectionShow';
import InvoiceShow from './InvoiceShow';
import QuotationShow from './QuotationShow';

/**
 * Action buttons for Order Show
 *
 * @param param0
 */
const OrderShowActions = ({ basePath, data, resource, history, ...props }: any) => {
  const classes = useStyle();
  const translate = useTranslate();
  const { permissions: permission } = usePermissions();
  if (!data || !permission) {
    return <Loading />;
  }
  const { status } = data;

  return (
    <TopToolbar className={classes.topToolbar}>
      {/* Admin can assign order to workshop or advisor */}
      {permission('orders.assign') && (canReassignWorkshop(status) || canReassignAdvisor(status)) && (
        <Button
          color="primary"
          variant="contained"
          onClick={() => history.push(`/orders/${data.id}/edit/?action=${OrderActions.ACCEPT}`)}
        >
          Assign Workshop/SA
        </Button>
      )}

      {/* Workshop can create quotation */}
      {permission('quotation.create') && status === OrderStatus.ORDER_INSPECTION_CAR_WITH_WO && (
        <Button
          color="primary"
          variant="contained"
          onClick={() => history.push(`/orders/${data.id}/edit/?action=${OrderActions.CREATE_OFFER}`)}
        >
          Create Quotation
        </Button>
      )}

      {/* Workshop can revise quotation */}
      {permission('quotation.revise') && canReviseQuotation(data) && (
        <Button
          color="primary"
          variant="contained"
          onClick={() => history.push(`/orders/${data.id}/edit/?action=${OrderActions.REVISE_QUOTATION}`)}
        >
          Revise Quotation
        </Button>
      )}

      {/* Workshop can enter the estimated date for car collection */}
      {permission('orders.update-collection-date') && canEstimateCollectionDate(status) && (
        <Button
          color="primary"
          variant="contained"
          onClick={() =>
            history.push(`/orders/${data.id}/edit/?action=${OrderActions.UPDATE_COLLECTION_DATE}`)
          }
        >
          Estimate Collection Date
        </Button>
      )}

      {/* Workshop can mark that the work is done */}
      {permission('orders.finish') && canFinishWork(status) && (
        <Button
          color="primary"
          variant="contained"
          onClick={() => history.push(`/orders/${data.id}/edit/?action=${OrderActions.WORK_DONE}`)}
        >
          Mark Work as Done
        </Button>
      )}

      {permission('orders.update-inspection-date') && canUpdateInspectionDate(status) && (
        <Button
          color="primary"
          variant="contained"
          onClick={() =>
            history.push(`/orders/${data.id}/edit/?action=${OrderActions.UPDATE_INSPECTION_DATE}`)
          }
        >
          Change Inspection Date
        </Button>
      )}

      {/* Admin can cancel order */}
      {permission('orders.cancel') && canCancel(status) && (
        <Button
          color="primary"
          onClick={() => history.push(`/orders/${data.id}/edit/?action=${OrderActions.CANCEL}`)}
        >
          Cancel Order
        </Button>
      )}
    </TopToolbar>
  );
};

/**
 * Display details of orders
 * @param props
 */
const OrderShow = (props: any) => {
  const { history } = props;
  const breadcrumbPaths = [
    { link: '/orders', title: 'Orders' },
    { link: `/orders/${props.id}/show`, title: props.id },
  ];
  const { permissions } = usePermissions();

  if (!permissions) {
    return <Loading />;
  }

  // @ts-ignore
  return (
    <>
      <TitleBreadcrumb paths={breadcrumbPaths} />
      <Show {...props} actions={<OrderShowActions history={history} />}>
        <TabbedShowLayout>
          <Tab label="Order Detail">
            <Detail {...props} />
          </Tab>
          <Tab label="Inspection Diagram">
            <InspectionShow {...props} />
          </Tab>
          {permissions('quotations.read') && (
            <Tab label="Workshop Quotation">
              <QuotationShow {...props} />
            </Tab>
          )}
          {permissions('invoices.read') && (
            <Tab label="Invoice">
              <InvoiceShow {...props} />
            </Tab>
          )}
        </TabbedShowLayout>
      </Show>
    </>
  );
};

export default OrderShow;
