export const OrderActions = {
  ACCEPT: 'accept',
  CREATE_OFFER: 'create-offer',
  REVISE_QUOTATION: 'revise-quotation',
  CANCEL_REVISED_QUOTATION: 'cancel-revised-quotation',
  CANCEL: 'cancel',
  UPDATE_COLLECTION_DATE: 'update-collection-date',
  WORK_DONE: 'work-done',
  UPDATE_INSPECTION_DATE: 'update-inspection-date',
};

export const OrderStatus = {
  ORDER_NEW: 'ORDER_NEW',
  ORDER_ASSIGNMENT_EXPIRED: 'ORDER_ASSIGNMENT_EXPIRED',
  ORDER_ASSIGNMENT_SUCCESS: 'ORDER_ASSIGNMENT_SUCCESS',
  ORDER_INSPECTION_STARTED: 'ORDER_INSPECTION_STARTED',
  ORDER_INSPECTION_CAR_WITH_SA: 'ORDER_INSPECTION_CAR_WITH_SA',
  ORDER_INSPECTION_CAR_WITH_WO: 'ORDER_INSPECTION_CAR_WITH_WO',
  ORDER_CANCEL: 'ORDER_CANCEL',
  ORDER_CANCEL_UNPAID: 'ORDER_CANCEL_UNPAID',
  ORDER_CANCEL_PAID: 'ORDER_CANCEL_PAID',
  ORDER_QUOTATION_START: 'ORDER_QUOTATION_START',
  ORDER_QUOTATION_EXPIRED: 'ORDER_QUOTATION_EXPIRED',
  ORDER_QUOTATION_EXPIRED_UNPAID: 'ORDER_QUOTATION_EXPIRED_UNPAID',
  ORDER_QUOTATION_EXPIRED_PAID: 'ORDER_QUOTATION_EXPIRED_PAID',
  ORDER_QUOTATION_ACCEPTED: 'ORDER_QUOTATION_ACCEPTED',
  ORDER_QUOTATION_PAID: 'ORDER_QUOTATION_PAID',
  ORDER_WORK_STARTED: 'ORDER_WORK_STARTED',
  ORDER_WORK_SUCCESS: 'ORDER_WORK_SUCCESS',
  ORDER_RETURN_CAR_WITH_SA: 'ORDER_RETURN_CAR_WITH_SA',
  ORDER_RETURN_CAR_SUCCESS: 'ORDER_RETURN_CAR_SUCCESS',
  ORDER_RATED: 'ORDER_RATED',
};

export const OrderConfirmed = [
  'ORDER_ASSIGNMENT_SUCCESS',
  'ORDER_INSPECTION_STARTED',
  'ORDER_INSPECTION_CAR_WITH_SA',
];
export const OrderInWorkshop = ['ORDER_INSPECTION_CAR_WITH_WO', 'ORDER_QUOTATION_START'];
export const OrderQuotationAccepted = ['ORDER_QUOTATION_ACCEPTED', 'ORDER_WORK_STARTED'];
export const OrderReadyForInspection = ['ORDER_WORK_SUCCESS'];
export const OrderClosed = ['ORDER_RETURN_CAR_SUCCESS', 'ORDER_RATED'];

export const FinanceDocumentStatus = {
  STATUS_DRAFT: 'draft',
  STATUS_OPEN: 'open',
  STATUS_ACCEPTED: 'accepted',
  STATUS_EXPIRED: 'expired',
  STATUS_CANCELLED: 'cancelled',
};

export const PromoCodeType = {
  percentage: 'Percentage',
  fixed_amount: 'Fixed Amount',
};

export const PromoCodeStatus = {
  active: 'Active',
  redeemed: 'Redeemed',
  inactive: 'Inactive',
  expired: 'Expired',
};

export const apiUrl = process.env.REACT_APP_API_URL;

export const roles = {
  ADMIN_SUPER: 'super_admin',
  ADMIN_CUSTOMER: 'customer_admin',
  ADMIN_FINANCE: 'finance_admin',
  WORKSHOP: 'workshop',
};

export const dayOfWeek = [
  { id: 1, name: 'Monday' },
  { id: 2, name: 'Tuesday' },
  { id: 3, name: 'Wednesday' },
  { id: 4, name: 'Thursday' },
  { id: 5, name: 'Friday' },
  { id: 6, name: 'Saturday' },
  { id: 7, name: 'Sunday' },
];

export const month = [
  { id: 1, name: 'January' },
  { id: 2, name: 'February' },
  { id: 3, name: 'March' },
  { id: 4, name: 'April' },
  { id: 5, name: 'May' },
  { id: 6, name: 'June' },
  { id: 7, name: 'July' },
  { id: 8, name: 'August' },
  { id: 9, name: 'September' },
  { id: 10, name: 'October' },
  { id: 11, name: 'November' },
  { id: 12, name: 'December' },
];
