import OrderList from './OrderList';
import JobIcon from '@material-ui/icons/Build';
import OrderShow from './OrderShow';
import { OrderEdit } from './OrderEdit';

export default {
    name: "orders",
    list: OrderList,
    show: OrderShow,
    edit: OrderEdit,
    icon: JobIcon,
}