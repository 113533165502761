import ServiceList from './ServicesList';
import ServiceCreate from './ServiceCreate';
import ServiceEdit from './ServiceEdit';
import ServiceIcon from '@material-ui/icons/SentimentSatisfiedAlt';

export default{
    list: ServiceList,
    edit: ServiceEdit,
    create: ServiceCreate,
    icon: ServiceIcon,
}