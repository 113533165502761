import React from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import { Order } from '../../types';
import { OrderStatus } from '../../utils/const';
import UpdateOrderDate from './UpdateOrderDate';

interface Props {
  id: number;
}
const WorkDone = ({ id }: Props) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [order, setOrder] = React.useState<Order | undefined>();

  React.useEffect(() => {
    dataProvider
      .getOne('orders', { id })
      .then(({ data }: { data: Order }) => {
        setOrder(data);
      })
      .catch((error: any) => {
        notify('Error: order cannot be fetched.', 'warning');
      });
  }, []);

  const handleClick = (date: string) =>
    dataProvider.update('orders', {
      id,
      data: {
        status: OrderStatus.ORDER_WORK_SUCCESS,
        collection_date: date,
      },
    });

  return (
    <UpdateOrderDate
      order={order}
      source="collection_date"
      onSubmit={handleClick}
      text="Do you want to mark the job as done? Please enter the collection date and time."
      onSuccessNotification="Job has been marked as done."
      onErrorNotification="Error: job cannot be marked as done."
    />
  );
};

export default WorkDone;
