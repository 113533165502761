import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/icons/Adjust';
import Button from '@material-ui/core/Button';
import get from 'lodash/get';
import React from 'react';
import { Issue } from '../../types';
import diagram from './diagram.png';
import { GridListPicture } from './GridListPicture';
import './inspection.scss';

interface Props {
  record?: object;
  source: string;
  label?: string;
}

const InspectionDiagram: React.FC<Props> = ({ record, source, label = '' }) => {
  const issues: Issue[] | null = get(record, source);
  const [selectedIssue, setSelectedIssue] = React.useState<Issue | null>(null);
  const a: Issue = {
    id: 1,
    key: 'A',
    picture_urls: [],
    notes: ''
  }

  const dots = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M'];
  const b = dots.map(key => ({...a, key}));

  return (
    <div className="InspectionDiagram">
      <div className="diagram">
        <img src={diagram} alt="Car diagram" />
        {issues?.map((item, index) => (
          <IconButton className={`icon${item.key}`} key={item.key} onClick={() => setSelectedIssue(item)}>
            <Icon color={selectedIssue?.id === item.id ? 'primary' : 'secondary'} /> {item.key}
          </IconButton>
        ))}
      </div>
      <div className="issue">
        {selectedIssue && <GridListPicture issue={selectedIssue} />}
      </div>
    </div>

    // <Grid container spacing={3}>
    //   <Grid item xs={12} md={4}>

    //   </Grid>
    //   <Grid item xs={12}>
    //     {selectedIssue && <GridListPicture issue={selectedIssue} />}
    //   </Grid>
    // </Grid>
  );
};

export default InspectionDiagram;
