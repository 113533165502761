import React from 'react';
import { Product } from "../../types";
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TextField from '@material-ui/core/TextField';
import CancelIcon from '@material-ui/icons/Cancel';
import ProductDropdown from './LineItemDropdown';
import { MoneyFormatter } from "../../components/Formatter";
import IconButton from "@material-ui/core/IconButton";


type Props = {
  index: number;
  products: Product[];
  description: string;
  quantity: number;
  unit_price: number;
  selectedProductId?: number;
  changeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  deleteHandler: () => void;
  // selectedProduct: ProductItem;
  // onUpdate: (v: ProductItem) => void;
  // [x: string]: any;

}

const LineItem: React.FC<Props> = (props) => {
  const { index, products, description, quantity, unit_price, selectedProductId = 0 } = props;
  const { deleteHandler, changeHandler } = props;

  return (
    <TableRow key={index + 1}>
      <TableCell>{index + 1}</TableCell>
      <TableCell>
        <ProductDropdown
          products={products}
          fullWidth
          selectedValue={selectedProductId}
          changeHandler={changeHandler}
        />
      </TableCell>
      <TableCell>
        <TextField
          name='description'
          value={description}
          multiline
          fullWidth
          onChange={changeHandler}
        />
      </TableCell>
      <TableCell align="center">
        <TextField
          name="quantity"
          value={quantity}
          type="number"
          fullWidth
          style={{ textAlign: "right" }}
          onChange={changeHandler}
        />
      </TableCell>
      <TableCell align="right">
        <TextField
          name="unit_price"
          value={unit_price}
          type="number"
          style={{ textAlign: "right" }}
          fullWidth
          onChange={changeHandler}
        />
      </TableCell>
      <TableCell align="right">
        <MoneyFormatter value={quantity * unit_price} />
      </TableCell>
      <TableCell>
        <IconButton color="secondary" onClick={deleteHandler}>
          <CancelIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

export default LineItem;