import React from 'react';
import { Edit, Show, Tab, TabbedShowLayout } from 'react-admin';
import OrderGrid from '../../components/OrderGrid';
import WorkshopForm from './WorkshopForm';
import { makeStyles } from '@material-ui/core';
import TitleBreadcrumb from '../../components/TitleBreadcrumb';

const useStyle = makeStyles({
  noshadow: {
    '& .ra-field': {
      marginBottom: '20px',
      '& .MuiPaper-rounded': {
        boxShadow: 'none',
      },
    },
  },
});

const WorkshopEdit = (props: any) => {
  const classes = useStyle();
  const { id } = props;
  const breadcrumb = [
    { link: '/workshops', title: 'Workshops' },
    { link: `/workshops/${id}`, title: id },
  ];
  return (
    <>
      <TitleBreadcrumb paths={breadcrumb} />
      <Show {...props} actions={<></>} className={classes.noshadow} title=" ">
        <TabbedShowLayout>
          <Tab label="Workshop Details">
            <Edit undoable={false} {...props}>
              <WorkshopForm />
            </Edit>
          </Tab>
          <Tab label="Orders">
            <OrderGrid target="workshop_id" emptyMessage="Workshop has not been assigned to any order yet." />
          </Tab>
        </TabbedShowLayout>
      </Show>
    </>
  );
};

export default WorkshopEdit;
