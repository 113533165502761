import { withStyles } from '@material-ui/core';
import React from 'react';
import { DeleteWithConfirmButton, SaveButton, Toolbar, usePermissions } from 'react-admin';

const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

interface Props {
  confirmDeleteContent: string;
  record?: any;
  saving?: any;
  savePermission?: string;
  deletePermission?: string;
  [x: string]: any;
}

const CustomToolbar = withStyles(toolbarStyles)(({ confirmDeleteContent, ...props }: Props) => {
  const { permissions } = usePermissions();
  const { savePermission, deletePermission } = props;

  if (!permissions) {
    return <></>;
  }

  return (
    <Toolbar {...props}>
      {permissions(savePermission) && <SaveButton />}
      {permissions(deletePermission) && <DeleteWithConfirmButton confirmContent={confirmDeleteContent} />}
    </Toolbar>
  );
});

export default CustomToolbar;
