import React from 'react';
import { Edit, TextInput, SimpleForm } from 'react-admin';
import RichTextInput from '../../../components/RichTextInput';
import TitleBreadcrumb from '../../../components/TitleBreadcrumb';
import SaveDeleteToolbar from '../../../components/SaveDeleteToolbar';

const FaqEdit = (props: any) => {
  const { id } = props;
  const breadcrumbPath = [
    { link: `/faqs`, title: `FAQs` },
    { link: `/faqs/${id}`, title: `${id}` },
  ];

  return (
    <>
      <TitleBreadcrumb paths={breadcrumbPath} />
      <Edit undoable={false} {...props} title="Edit FAQ">
        <SimpleForm
          toolbar={
            <SaveDeleteToolbar
              confirmDeleteContent={`Are you sure to delete this?`}
              savePermission={`faqs.update`}
              deletePermission={`faqs.delete`}
            />
          }
        >
          <TextInput source="display_order" type="number" />
          <TextInput source="question" />
          <RichTextInput source="answer" />
        </SimpleForm>
      </Edit>
    </>
  );
};

export default FaqEdit;
