import { OrderStatus } from './const';

/**
 * Check if order can be reassigned with a new Advisor
 * @param status status string
 */
export const canReassignAdvisor = (status: string) =>
  [
    OrderStatus.ORDER_NEW,
    OrderStatus.ORDER_ASSIGNMENT_SUCCESS,
    OrderStatus.ORDER_INSPECTION_STARTED,
    OrderStatus.ORDER_INSPECTION_CAR_WITH_SA,
    OrderStatus.ORDER_INSPECTION_CAR_WITH_WO,
    OrderStatus.ORDER_QUOTATION_START,
    OrderStatus.ORDER_QUOTATION_EXPIRED,
    OrderStatus.ORDER_QUOTATION_EXPIRED_UNPAID,
    OrderStatus.ORDER_QUOTATION_EXPIRED_PAID,
    OrderStatus.ORDER_QUOTATION_ACCEPTED,
    OrderStatus.ORDER_QUOTATION_PAID,
    OrderStatus.ORDER_WORK_STARTED,
    OrderStatus.ORDER_WORK_SUCCESS,
    OrderStatus.ORDER_RETURN_CAR_WITH_SA,
  ].find((el) => el === status)
    ? true
    : false;

/**
 * Check if order can be reassigned with a new workshop
 * @param status Status string
 */
export const canReassignWorkshop = (status: string) =>
  [
    OrderStatus.ORDER_NEW,
    OrderStatus.ORDER_ASSIGNMENT_SUCCESS,
    OrderStatus.ORDER_INSPECTION_STARTED,
    OrderStatus.ORDER_INSPECTION_CAR_WITH_SA,
  ].find((el) => el === status)
    ? true
    : false;

/**
 * order can be cancel, but admin cannot configure the charges.
 *
 * @param status string
 */
export const canCancel = (status: string) =>
  [
    OrderStatus.ORDER_NEW,
    OrderStatus.ORDER_ASSIGNMENT_SUCCESS,
    OrderStatus.ORDER_INSPECTION_STARTED,
    OrderStatus.ORDER_INSPECTION_CAR_WITH_SA,
    OrderStatus.ORDER_INSPECTION_CAR_WITH_WO,
    OrderStatus.ORDER_QUOTATION_START,
    OrderStatus.ORDER_QUOTATION_EXPIRED,
    OrderStatus.ORDER_QUOTATION_EXPIRED_UNPAID,
    OrderStatus.ORDER_QUOTATION_EXPIRED_PAID,
    OrderStatus.ORDER_QUOTATION_ACCEPTED,
    OrderStatus.ORDER_QUOTATION_PAID,
    OrderStatus.ORDER_WORK_STARTED,
    OrderStatus.ORDER_WORK_SUCCESS,
    OrderStatus.ORDER_RETURN_CAR_WITH_SA,
  ].find((el) => el === status)
    ? true
    : false;

/**
 * order can be cancel, and admin can configure the charges.
 *
 * @param status string
 */
export const canCancelWithFee = (status: string) =>
  [
    OrderStatus.ORDER_ASSIGNMENT_SUCCESS,
    OrderStatus.ORDER_INSPECTION_STARTED,
    OrderStatus.ORDER_INSPECTION_CAR_WITH_SA,
    OrderStatus.ORDER_INSPECTION_CAR_WITH_WO,
    OrderStatus.ORDER_QUOTATION_START,
    OrderStatus.ORDER_QUOTATION_EXPIRED,
    OrderStatus.ORDER_QUOTATION_EXPIRED_UNPAID,
    OrderStatus.ORDER_QUOTATION_EXPIRED_PAID,
    OrderStatus.ORDER_QUOTATION_ACCEPTED,
    OrderStatus.ORDER_QUOTATION_PAID,
    OrderStatus.ORDER_WORK_STARTED,
    OrderStatus.ORDER_WORK_SUCCESS,
    OrderStatus.ORDER_RETURN_CAR_WITH_SA,
  ].find((el) => el === status)
    ? true
    : false;

/**
 * Order can be cancelled, but order will be charged full
 * @param status string
 */
export const willBeChargedFullWhenCancel = (status: string) =>
  [
    OrderStatus.ORDER_QUOTATION_ACCEPTED,
    OrderStatus.ORDER_QUOTATION_PAID,
    OrderStatus.ORDER_WORK_STARTED,
    OrderStatus.ORDER_WORK_SUCCESS,
    OrderStatus.ORDER_RETURN_CAR_WITH_SA,
  ].find((el) => el === status)
    ? true
    : false;

/**
 * Workshop can update the estimated date of when the car
 * can be collected.
 *
 * @param status string
 */
export const canEstimateCollectionDate = (status: string) =>
  [OrderStatus.ORDER_QUOTATION_ACCEPTED].find((el) => el === status) ? true : false;

/**
 * Workshop can mark that work has been done
 *
 * @param status string
 */
export const canFinishWork = (status: string) =>
  [OrderStatus.ORDER_WORK_STARTED].find((el) => el === status) ? true : false;

export const canReviseQuotation = ({ status, revised_quotation_id, ...props }: any) => {
  const isStatusOk = [OrderStatus.ORDER_QUOTATION_ACCEPTED, OrderStatus.ORDER_WORK_STARTED].find(
    (el) => el === status,
  )
    ? true
    : false;

  console.log(status, isStatusOk, props, revised_quotation_id);
  return !revised_quotation_id && isStatusOk;
};

/**
 * Check if inspection date of an Order can be changed
 *
 * @param status string
 */
export const canUpdateInspectionDate = (status: string) =>
  [OrderStatus.ORDER_NEW, OrderStatus.ORDER_ASSIGNMENT_SUCCESS].find((el) => el === status) ? true : false;
