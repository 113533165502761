import React from 'react';
import { Create } from 'react-admin';
import TitleBreadcrumb from '../../components/TitleBreadcrumb';
import PromoCodeForm from './PromoCodeForm';

const PromoCodeCreate = (props: any) => {
  const breadcrumb = [
    { link: '/promo-codes', title: 'Promo Codes' },
    { link: `/promo-codes/create`, title: 'Create' },
  ];

  return (
    <>
      <TitleBreadcrumb paths={breadcrumb} />
      <Create {...props} title="Create Promo Code">
        <PromoCodeForm mode="create" />
      </Create>
    </>
  );
};

export default PromoCodeCreate;
