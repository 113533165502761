import React from 'react';
import { Create, ReferenceInput, SelectInput, SimpleForm, TextInput } from 'react-admin';
import TitleBreadcrumb from '../../../components/TitleBreadcrumb';
import ImageInput from '../../../components/ImageInput';

const CarModelCreate = (props: any) => {
  const breadcrumb = [
    { link: '/car-models', title: 'Car Models' },
    { link: `/car-models/create`, title: 'Create' },
  ];

  return (
    <>
      <TitleBreadcrumb paths={breadcrumb} />
      <Create {...props} title="Create Car Model">
        <SimpleForm>
          <ReferenceInput
            label="Car Make"
            source="car_maker_id"
            reference="car-makers"
            sort={{ field: 'name', order: 'ASC' }}
            perPage={1000}
            fullWidth
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
          <TextInput source="name" fullWidth />
        </SimpleForm>
      </Create>
    </>
  );
};

export default CarModelCreate;
