import React from 'react';
import { ReferenceManyField, TextField, ReferenceField } from 'react-admin';
import Datagrid from '../CustomDatagrid';
import StatusField from '../StatusField';
import CustomDateField from '../CustomDateField';

interface Props {
  target: string;
  emptyMessage?: string;
  [x: string]: any;
}

const OrderGrid = ({ target, ...props }: Props) => {
  const handleRowClick = (id: number) => `/orders/${id}/show`;

  return (
    <ReferenceManyField reference="orders" target={target} {...props}>
      <Datagrid rowClick={handleRowClick} emptyMessage={props.emptyMessage}>
        <TextField source="id" />
        <CustomDateField source="created_at" />
        <ReferenceField source="customer_id" reference="customers">
          <TextField source="full_name" />
        </ReferenceField>
        <ReferenceField source="advisor_id" reference="advisors">
          <TextField source="full_name" />
        </ReferenceField>
        <ReferenceField source="workshop_id" reference="workshops">
          <TextField source="full_name" />
        </ReferenceField>
        <StatusField source="status" />
      </Datagrid>
    </ReferenceManyField>
  );
};

export default OrderGrid;
